// NotificationCard.jsx
import React from 'react';
import { Avatar, Button, Typography, Paper } from '@mui/material';

const NotificationCard = ({ notification, onDelete }) => {
  const { avatar, title, message } = notification;

  return (
    <Paper sx={{ display: 'flex', padding: 2, marginBottom: 2, alignItems: 'center', gap: 2 }}>
      <Avatar src={avatar} alt={title} />
      <div style={{ flexGrow: 1 }}>
        <Typography variant="body1" >
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {message}
        </Typography>
      </div>
      <Button variant="text" color="error" onClick={onDelete}>
        Delete
      </Button>
    </Paper>
  );
};

export default NotificationCard;
