import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ChiefAgentDashboard from './pages/ChiefAgent/ChiefAgentDashboard';
// import ClientDashboard from './pages/client/ClientDashboard';
import NotFound from './pages/NotFound';
import AgentDashboard from './pages/Agents/AgentDashboard';
import NewAgent from './pages/ChiefAgent/NewAgent';
import ChiefAgentTarget from './pages/ChiefAgent/ChiefAgentTarget';
import ChiefAgentWithdraw from './pages/ChiefAgent/ChiefAgentWithdraw';
import AgentWithdraw from './pages/Agents/AgentWithdraw';
import AgentTarget from './pages/Agents/AgentTarget';
import ChiefAgentContact from './pages/ChiefAgent/ChiefAgentContact';
import AgentContact from './pages/Agents/AgentContact';
import NewClient from './pages/Agents/NewClient';
import CircularWithValueLabel from './components/Loading';
import SuccessPage from './components/SuccessPage';
import Profile from './components/Profile';
import ClientDashboard from './pages/Client/ClientDashboard';
import ClientDeposits from './pages/Client/ClientDeposits';
import ClientTargeting from './pages/Client/ClientTargeting';
import RepresentativeDashboard from './pages/Representative/RepresentativeDashboard';
import NewUser from './pages/Representative/NewUser';
import RepresentativeContact from './pages/Representative/RepresentativeContact';
import RepresentativeAgents from './pages/Representative/RepresentativeAgents';
import RepresentativeClients from './pages/Representative/RespresentativeClients';
import RepresentativeAgency from './pages/Representative/RepresentativeAgency';
import LandingPage from './pages/LandingPage';
import SignIn from './pages/Auth/SigninPage';
import SignUp from './pages/Auth/SignupPage';
import EmailConfirm from './pages/EmailConfirm';
import PasswordReset from './pages/PasswordReset';
import VerificationConfirm from './pages/VerificationConfirm';
import MailSent from './pages/NewFile';
import ResetConfirm from './pages/ResetConfirm';
import TermsPage from './pages/TermsAndServices';
import AgentsDetails from './pages/ChiefAgent/AgentDetails';
import AuthCheck from './pages/Auth/AuthCheck';
import ChiefAgentReport from './pages/ChiefAgent/ChiefAgentReport';
import ClientFeedback from './pages/Client/ClientFeedback';
import ClientNotification from './pages/Client/ClientNotification';
import PrivateRoute from './pages/Auth/PrivateRoute';
import ProtectedRoute from './pages/Auth/ProtectedRoute';

function App() {
  return (
    <Router>
      <Routes>

        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/signin" element={<AuthRedirect><SignIn /></AuthRedirect>} /> */}
        <Route path="/login" element={<SignIn />} />
        <Route path="/terms" element={<TermsPage />} />
        {/* <Route path="/register" element={<AuthRedirect><SignUp /></AuthRedirect>} /> */}
        <Route path="/register" element={<SignUp />} />
        <Route path="/password-forgot" element={<EmailConfirm />} />
        <Route path="/reset_password/:token" element={<PasswordReset />} />
        <Route path="/success_verification/:token" element={<VerificationConfirm />} />
        <Route path="/mailsent" element={<MailSent />} />
        <Route path="/resetconfirm" element={<ResetConfirm />} />

        {/* Chief Agent routes */}
        <Route path="/chief-agent-dashboard" element={<PrivateRoute><ProtectedRoute allowedRoles={['chief-agent']}><ChiefAgentDashboard /></ProtectedRoute></PrivateRoute>} />
        <Route path="/chief-agent-dashboard/:id" element={<PrivateRoute><ProtectedRoute allowedRoles={['chief-agent']}><AgentsDetails /></ProtectedRoute></PrivateRoute>} />
        <Route path="/new-agent" element={<PrivateRoute><ProtectedRoute allowedRoles={['chief-agent']}><NewAgent /></ProtectedRoute></PrivateRoute>} />
        <Route path="/chief-agent-contacts" element={<PrivateRoute><ProtectedRoute allowedRoles={['chief-agent']}><ChiefAgentContact /></ProtectedRoute></PrivateRoute>} />
        <Route path="/chief-agent-target" element={<PrivateRoute><ProtectedRoute allowedRoles={['chief-agent']}><ChiefAgentTarget /></ProtectedRoute></PrivateRoute>} />
        <Route path="/chief-agent-report" element={<PrivateRoute><ProtectedRoute allowedRoles={['chief-agent']}><ChiefAgentReport /></ProtectedRoute></PrivateRoute>} />
        <Route path="/chief-agent-withdraws" element={<PrivateRoute><ProtectedRoute allowedRoles={['chief-agent']}><AuthCheck><ChiefAgentWithdraw /></AuthCheck></ProtectedRoute></PrivateRoute>} />


        {/* Agent routes */}
        <Route path="/agent-dashboard" element={<PrivateRoute><ProtectedRoute allowedRoles={['agent']}><AgentDashboard /></ProtectedRoute></PrivateRoute>} />
        <Route path="/agent-withdraws" element={<PrivateRoute><ProtectedRoute allowedRoles={['agent']}><AuthCheck><AgentWithdraw /></AuthCheck></ProtectedRoute></PrivateRoute>} />
        <Route path="/agent-target" element={<PrivateRoute><ProtectedRoute allowedRoles={['agent']}><AgentTarget /></ProtectedRoute></PrivateRoute>} />
        <Route path="/agent-contacts" element={<PrivateRoute><ProtectedRoute allowedRoles={['agent']}><AgentContact /></ProtectedRoute></PrivateRoute>} />
        <Route path="/new-client" element={<PrivateRoute><ProtectedRoute allowedRoles={['agent']}><NewClient /></ProtectedRoute></PrivateRoute>} />


        {/* Client routes */}
        <Route path="/dashboard" element={<PrivateRoute><ProtectedRoute allowedRoles={['client']}><ClientDashboard /></ProtectedRoute></PrivateRoute>} />
        <Route path="/target" element={<PrivateRoute><ProtectedRoute allowedRoles={['client']}><ClientTargeting /></ProtectedRoute></PrivateRoute>} />
        <Route path="/feedback" element={<PrivateRoute><ProtectedRoute allowedRoles={['client']}><ClientFeedback /></ProtectedRoute></PrivateRoute>} />
        <Route path="/deposits" element={<PrivateRoute><ProtectedRoute allowedRoles={['client']}><ClientDeposits /></ProtectedRoute></PrivateRoute>} />
        <Route path="/notifications" element={<PrivateRoute><ProtectedRoute allowedRoles={['client']}><ClientNotification /></ProtectedRoute></PrivateRoute>} />


        {/* Representative routes */}
        <Route path="/admin-dashboard" element={<PrivateRoute><ProtectedRoute allowedRoles={['inverse-representative']}><RepresentativeDashboard /></ProtectedRoute></PrivateRoute>} />
        <Route path="/admin-dashboard/:id" element={<PrivateRoute><ProtectedRoute allowedRoles={['inverse-representative']}><RepresentativeDashboard /></ProtectedRoute></PrivateRoute>} />
        <Route path="/admin-contacts" element={<PrivateRoute><ProtectedRoute allowedRoles={['inverse-representative']}><RepresentativeContact /></ProtectedRoute></PrivateRoute>} />
        <Route path="/new-user" element={<PrivateRoute><ProtectedRoute allowedRoles={['inverse-representative']}><NewUser /></ProtectedRoute></PrivateRoute>} />
        <Route path="/admin-clients" element={<PrivateRoute><ProtectedRoute allowedRoles={['inverse-representative']}><RepresentativeClients /></ProtectedRoute></PrivateRoute>} />
        <Route path="/admin-agents" element={<PrivateRoute><ProtectedRoute allowedRoles={['inverse-representative']}><RepresentativeAgents /></ProtectedRoute></PrivateRoute>} />
        <Route path="/admin-agency" element={<PrivateRoute><ProtectedRoute allowedRoles={['inverse-representative']}><RepresentativeAgency /></ProtectedRoute></PrivateRoute>} />


        {/* Other pages */}
        <Route path='/loading' element={<CircularWithValueLabel />} />
        <Route path='/successful' element={<SuccessPage />} />
        <Route path='/profile' element={<Profile />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
