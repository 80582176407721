import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import axios from 'axios';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const columns = [
  {
    field: 'fullName',
    headerName: 'Full Name',
    width: 150,
    valueGetter: (value, row) => `${row.first_name || ''} ${row.last_name || ''}`,
  },
  { field: 'telephone', headerName: 'Telephone', width: 100 },
  { field: 'email', headerName: 'Email', width: 130 },
  { field: 'age', headerName: 'Age', type: 'number', width: 60 },
  { field: 'city', headerName: 'City', width: 80 },
  { field: 'gender', headerName: 'Gender', width: 60 },
  { field: 'business_categories', headerName: 'Business Categories', width: 200 },
  { field: 'interests', headerName: 'Interests', width: 200 },
];

const paginationModel = { page: 0, pageSize: 5 };

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

const Clients = () => {
  const [userData, setUserData] = useState([]);
  const [row, setRow] = useState([]);
  const [bearerToken, setBearerToken] = useState('');

  const fetchUsers = async (token) => {
    try {
      const response = await axios.get('https://interstation.ibrahimsherif.com/api/referrals', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(response.data.data); // Assuming response.data.data is the correct structure
    } catch (error) {
      // console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('token'));
    if (storedData) {
      const token = storedData.data.token;
      setBearerToken(token);
      fetchUsers(token);
    }
  }, []);

  useEffect(() => {
    const clientsData = userData.filter(user => user.role === "client");
    setRow(clientsData);
  }, [userData]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <h2>Clients</h2>
      </div>

      <Paper sx={{ height: 400, width: '100%' }}>
        <DataGrid
          slots={{
            toolbar: CustomToolbar,
          }}
          rows={row}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10, 25]}
          getRowId={(row) => row.telephone}
          sx={{ border: 0 }}
        />
      </Paper>
    </div>
  );
};

export default Clients;
