import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  styled,
} from '@mui/material';
import Grid from '@mui/material/Grid2';


const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
}));

const DetailItem = ({ label, value }) => (
  <Box display="flex" justifyContent="space-between" mb={1}>
    <Typography variant="body2">{label}</Typography>
    <Typography variant="body2" color="text.secondary">
      {value}
    </Typography>
  </Box>
);

const AgentsDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams(); //
    const [user, setUser] = useState(null);
  
    useEffect(() => {
      // Retrieve the full userData array from localStorage
      const storedUserData = localStorage.getItem('userData'); // Assumes 'userData' contains an array of users
      
      if (storedUserData) {
        const users = JSON.parse(storedUserData); // Parse the stringified array of users
        const user = users.find(user => user._id === id); // Find the user with the given _id
    
        if (user) {
          setUser(user); // Set the specific user data
        } else {
          // console.error('User not found with the given id');
        }
      } else {
        // console.error('User data not found in localStorage');
      }
    }, [id]);
  
    if (!user) return <div>Loading...</div>;
  return (
    <Box sx={{ background: 'linear-gradient(180deg, #F4D1B9 0%, #B62D57 100%)', minHeight: '100vh' }} >
      <Grid container spacing={4}  display={"flex"} justifyContent={"center"} sx={{pt: 10, px:3}}>
        <Grid size={{ xs: 12, md: 4 }}>
          <StyledCard>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                <Box position="relative">
                  {/* <Avatar
                    src="/images/avatar.jpg"
                    // src="/images/avatar.jpg"
                    sx={{ width: 100, height: 100, mb: 1 }}
                  >
                    JD
                  </Avatar> */}
                  <AccountCircleIcon sx={{ width: 100, height: 100, mb: 1 }} />
                </Box>
                <Typography variant="h6">{`${user.first_name} ${user.last_name}`}</Typography>
                <Typography variant="body2" color="text.secondary">
                    {user.city}, {user.country || 'Cameroon'}
                </Typography>
              </Box>
              <DetailItem label="Email" value={user.email} />
              <DetailItem label="Telephone" value={user.telephone || 'N/A'} />
              <DetailItem label="Age" value={user.age || 'N/A'} />
              <Box display="flex" justifyContent="space-around" mt={2} >
              <Button
                variant="contained"
                sx={{ bgcolor: '#1F1F1F', '&:hover': { bgcolor: '#B62D57' } }}
                onClick={() => window.location.href = `tel:+237${user.telephone}`}
              >
                <PhoneIcon/>
              </Button>

              <Button
                variant="contained"
                sx={{ bgcolor: '#65D151', '&:hover': { bgcolor: '#1F1F1F' } }}
                onClick={() => window.location.href = `https://wa.me/+237${user.telephone}`}
              >
               <WhatsAppIcon /> 
              </Button>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
        <StyledCard>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Gauge
              value={Math.min(user.daily_activation, 100)} // Ensure value does not exceed 100
              width={250}  // Larger size for better visibility
              height={220}
              valueMax={100}
              startAngle={-110}
              endAngle={110}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 24, // Slightly larger font size
                  transform: 'translate(0px, 10px)', // Center the text better
                },
              }}
              text={({ value, valueMax }) => `${value} / ${valueMax}`} // Display value in "X / Y" format
            />
            <Typography variant="h6" align="center" mt={1}>
              Daily Activation
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={2} >
                <Button variant="contained" onClick={() => navigate(-1)} sx={{  bgcolor: '#B62D57', '&:hover': { bgcolor: '#F4D1B9' } }}>
                  Back
                </Button>
              </Box>
        </CardContent>
      </StyledCard>
    </Grid>
      </Grid>
    </Box>
  );
}
export default AgentsDetails;