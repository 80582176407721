import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
// import "./SignupPage.css";
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';
import ListItemText from '@mui/material/ListItemText';


const steps = ['Referral Registration', 'Payment Information', 'Account Creation'];

const defaultTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: '#B82956',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#B82956',
          },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: '#B82956',
              borderWidth: '0.15rem',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#B82956',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          fontSize: '1rem',
        },
      },
    },
  },
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function SignUp() {
  const [activeStep, setActiveStep] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [telephone, setTelephone] = useState('');
  const [confirm, setConfirm] = useState('');
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [errorTelephone, setErrorTelephone] = useState('');
  const [referral, setReferral] = useState('');
  const [errorReferral, setErrorReferral] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [errorPayment, setErrorPayment] = useState('')
  const [paymentTelephone, setPaymentTelephone] = useState('');
  const [errorPaymentTelephone, setErrorPaymentTelephone] = useState('')
  const [business, setBusiness] = useState([]);
  const [errorBusiness, setErrorBusiness] = useState([]);
  const [categories, setCategory] = useState([]);
  const [interest, setInterest] = useState([]);
  const [errorInterest, setErrorInterest] = useState([]);
  const [interestCenter, setInterestCenter] = useState([]);
  const [city, setCity] = useState('');
  const [errorCity, setErrorCity] = useState('');
  const [gender, setGender] = useState('');
  const [errorGender, setErrorGender] = useState('');
  const [firstName, setFirstName] = useState('');
  const [errorFirstName, setErrorFirstName] = useState('');
  const [uuid, setUuid] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [otherBusiness, setOtherBusiness] = useState('');
  const [language, setLanguage] = useState('');
  const [errorLanguage, setErrorlanguage] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [errorDateOfBirth, setErrorDateOfBirth] = useState('');
  const [password, setPassword] = useState('');
  const [re_password, setRePassword] = useState('');
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [temporaryToken, setTemporaryToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorDeposit, setErrorDeposit] = useState('');

  const [open, setOpen] = React.useState(false);
  const handleValidate = async () => {
    setIsConfirmDisabled(true);
    try {
      setLoading(true);
      const response = await axios.post('https://interstation.ibrahimsherif.com/api/register/1', {
        telephone: paymentTelephone,
        payment_method: paymentMethod,
        temporary_token: temporaryToken,
        uuid: uuid,
      });
      setLoading(false);
        setActiveStep(parseInt(response.data.data.next_step));
        setOpen(false);
    } catch (error) {
      // Handle pending and other error statuses in the catch block
      if (error.response?.data?.status === "pending") {
        setConfirm('Your payment is still pending and will be cancelled if not confirmed in 2 minutes');
        pollPaymentStatus(); // Start polling for the payment status
      } else {
        setLoading(false);
        setOpen(false);
        setError("Payment is unsuccessful, please retry");
        // console.error('Error during payment:', error);
        setIsConfirmDisabled(false);
      }
    }
    setConfirm('');
  };
  
  // Polling function to check payment status periodically
  const pollPaymentStatus = () => {
    const intervalId = setInterval(async () => {
      try {
        const response = await axios.post('https://interstation.ibrahimsherif.com/api/register/1', {
          telephone: paymentTelephone,
          payment_method: paymentMethod,
          temporary_token: temporaryToken,
          uuid: uuid,
        });
        clearInterval(intervalId); // Stop polling
          setLoading(false);
          setActiveStep(parseInt(response.data.data.next_step));
          setOpen(false);
      } catch (error) {
        // Handle failed or other error statuses
        if (error.response?.data?.status === "failed") {
          clearInterval(intervalId); // Stop polling
          setLoading(false);
          setError("Payment failed, please retry");
          setIsConfirmDisabled(false);
          setOpen(false);
        } else if (error.response?.data?.status !== "pending") {
          // Handle unexpected errors and stop polling
          clearInterval(intervalId);
          setLoading(false);
          setError("An error occurred while checking payment status.");
          setIsConfirmDisabled(false);
          // console.error('Error during payment status check:', error);
          setOpen(false);
        }
        // Continue polling if status is still pending
      }
    }, 5000); // Poll every 5 seconds

  };
  const handleClose = () => {
    setOpen(false);
  };

  const today = new Date().toISOString().split('T')[0];

    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        if (selectedDate > today) {
            setErrorDateOfBirth(true);
        } else {
            setErrorDateOfBirth(false);
            setDateOfBirth(selectedDate);
        }
    };

  const handleChange = (event) => {
    const {
        target: { value },
    } = event;
    setInterest(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
    );
};
const handleChange2 = (event) => {

  const {
      target: { value },
  } = event;
  setBusiness(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
  );
};
const handleChangeWhatsapp = (e) => {
  const { value } = e.target;
  if (/^\d*$/.test(value) && value.length <= 9) {
    setTelephone(value);
    setErrorTelephone('');
  } else {
    setErrorTelephone('Enter a valid Telephone number (9 digits max)');
  }
};
const handleChangeTelephone = (e) => {
  const { value } = e.target;
  if (/^\d*$/.test(value) && value.length <= 9) {
    setPaymentTelephone(value);
    setErrorPaymentTelephone('');
  } else {
    setErrorPaymentTelephone('Enter a valid Payment Telephone number (9 digits max)');
  }
};
const handleReferralChange = (e) => {
  const { value } = e.target;
  if (value.length <= 7) {
    setReferral(value);
    setErrorReferral('');
  } else {
    setErrorReferral('Referral code should be 7 characters');
  }
};

const [errors, setErrors] = useState({});

  const validateStep = () => {
    let newErrors = {};
    if(activeStep === 2) {
      if (!re_password) newErrors.re_password = 'Re type password please';
      if (password !== re_password) newErrors.re_password = 'Passwords do not match';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    // console.log('Active step:', activeStep);
    const fetchData = async () => {
      try {
        const response = await axios.get('https://interstation.ibrahimsherif.com/api/categories');
        setCategory(response.data.data);
       
        const interestCenterResponse = await axios.get('https://interstation.ibrahimsherif.com/api/interests');
        setInterestCenter(interestCenterResponse.data.data);
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleNext = async () => {
    

    if (activeStep === 0) {
      setLoading(true);
      try {
        const response = await axios.post('https://interstation.ibrahimsherif.com/api/register/0', {
          telephone: telephone,
          referral: referral,
        });
        setLoading(false);
        setActiveStep(parseInt(response.data.data.next_step));
        setTemporaryToken(response.data.data.temporary_token);
        setError('')
      } catch (error) {
        setLoading(false);
        setError(error.response.data.message);
      }
      
    }

    if (activeStep === 1) {
      setError('')
      try {
        setLoading(true);
        setIsDisabled(true);
        setIsChecked(false);
        const response = await axios.post('https://interstation.ibrahimsherif.com/api/register/1', {
          telephone: paymentTelephone,
          payment_method: paymentMethod,
          temporary_token: temporaryToken
        });
        setLoading(false);
        setOpen(true);
        setIsDisabled(false);
        setIsChecked(true);
        setUuid(response.data.uuid);
        setError('')
      } catch (error) {
        setLoading(false);
        setIsDisabled(false);
        setIsChecked(true);
        // console.log(error.response)
        // console.log(error.response.data.message)
        // setError(error.response.data.message);
        setErrorDeposit(error.response.data.message);
      }
      
    }if(activeStep === 2 && validateStep()){
        try {
          setLoading(true);
          const response = await axios.post('https://interstation.ibrahimsherif.com/api/register/2', {
            temporary_token: temporaryToken,
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            city: city,
            gender: gender,
            business_category: business,
            interest: interest,
            date_of_birth: dateOfBirth,
            language: language,
            other_business: otherBusiness,
          });
          setLoading(false);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          const { token } = response.data; 
          localStorage.setItem('token', token);
        } catch (error) {
          setLoading(false);
          setError(error.response.data.data);
          setErrorFirstName(error?.response.data.data.find(item => item.first_name)?.first_name);
          setErrorEmail(error.response.data.data.find(item => item.email)?.email);
          setErrorDateOfBirth(error.response.data.data.find(item => item.date_of_birth)?.date_of_birth);
          setErrorGender(error.response.data.data.find(item => item.gender)?.gender);
          setErrorCity(error.response.data.data.find(item => item.city)?.city);
          setErrorBusiness(error.response.data.data.find(item => item.business_category)?.business_category);
          setErrorInterest(error.response.data.data.find(item => item.interest)?.interest);
          setErrorlanguage(error.response.data.data.find(item => item.language)?.language);
          // console.log(error.response)
        }
        
      }
    
  };


  const handleLogin = () => {
    navigate('/login')
  };

  return (
    
    <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box sx={{ width: '100%', marginTop: '80px' }}>
        {loading && (
        <Box sx={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
          <LinearProgress />
        </Box>
      )}
            <Typography component="h1" variant="h5" style={{ marginBottom: "20px", textAlign:'center' }}>
                Sign Up
            </Typography>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                return (
                    <Step key={index}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1, justifyContent: 'center'}}>
                    All steps completed - You can login
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleLogin}>Login</Button>
                </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Box sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    {activeStep === 0 && (
                      <>
                      <FormControl fullWidth sx={{ py: 3 }} error={!!errorTelephone}>
                        <InputLabel htmlFor="outlined-error-helper-text">Whatsapp number</InputLabel>
                        <OutlinedInput
                          id="outlined-error-helper-text"
                          startAdornment={<InputAdornment position="start">+ 237</InputAdornment>}
                          required
                          label="Whatsapp number"
                          margin="dense"
                          name="telephone"
                          value={telephone}
                          type="number"
                          onChange={handleChangeWhatsapp}
                          error={!!errorTelephone}
                          autoFocus
                        />
                        {/* Add FormHelperText for error message */}
                        {errorTelephone && (
                          <FormHelperText>{errorTelephone}</FormHelperText>
                        )}
                      </FormControl>
                        <TextField
                          margin="dense"
                          id="outlined-error-helper-text"
                          required
                          fullWidth
                          name="referral"
                          label="Referral Code"
                          type="text"
                          value={referral}
                          onChange={handleReferralChange}
                          error={!!errorReferral} // This will mark the field as errored if errorReferral has a value
                          helperText={errorReferral || ""} // Display the error message if it exists
                          autoFocus
                        />
                        
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox  onChange={ (e) => setIsChecked(e.target.checked)} name="enableButton" checked={isChecked} />
                            }
                            label="Agree with terms and conditions"
                          />
                          {error && <Typography color="error" variant="body2" sx={{ mt: 2 }}>{error}</Typography>}
                          <span>By using our App, you agree to comply with these terms and conditions. Click <a style={{ color: 'blue' }} href={'/terms'} >here</a> to know more about.</span>
                        </FormGroup>
                      </>
                    )}
                    {activeStep === 1 && (
                  <>
                    <FormControl fullWidth error={!!errorPayment}>
                      <InputLabel id="payment-method">Payment Method</InputLabel>
                      <Select
                        labelId="payment-method"
                        id="payment-method-select"
                        value={paymentMethod}
                        label="Payment Method"
                        name="paymentMethod"
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <MenuItem value={"Momo"}>Mobile Money</MenuItem>
                        <MenuItem value={"OM"}>Orange Money</MenuItem>
                      </Select>
                      {errorPayment && <p style={{ color: 'red' }}>Payment Method is required</p>}
                      
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 3 }} error={!!errorPaymentTelephone}>
                          <InputLabel htmlFor="outlined-adornment-amount">Payment number</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">+ 237</InputAdornment>}
                            required
                            margin="dense"
                            name="paymentTelephone"
                            label="Payment Number"
                            type="number"
                            value={paymentTelephone }
                            onChange={handleChangeTelephone}
                            error={!!errorPaymentTelephone}
                            inputProps={{ maxLength: 9 }} 
                            autoFocus
                          />
                        {errorPaymentTelephone && (
                          <FormHelperText style={{ color: 'red' }}>{errorPaymentTelephone}</FormHelperText>
                        )}
                        {errorDeposit && (
                        <FormHelperText style={{ color: 'red' }}>{errorDeposit}</FormHelperText>
                        )}
                        </FormControl>
                        <div>
                          <Modal
                            open={open}
                            // onClose={handleClose}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                          >
                            <Box sx={{ ...style, width: 400 }}>
                              <h2 id="parent-modal-title">Payment Validation</h2>
                              <p id="parent-modal-description">
                              You wish to pay by {paymentMethod}. Please validate the payment at &nbsp;
                                { paymentMethod === "OM" ? <span style={{ color: 'red' }}>#150*50#</span> : <span style={{ color: 'red' }}>*126#</span> }&nbsp; and click on confirm once the payment has been made</p>
                                <p>{confirm}</p>
                              <Button onClick={handleValidate} disabled={isConfirmDisabled}>Confirm</Button>
                            </Box>
                          </Modal>
                        </div>
                        {error && <p style={{ color: 'red' }}>Payment failed, please restart</p>}
                    </>
                              )}

                    {activeStep === 2 && (
                      <div className='account'>
                        <TextField
                        sx={{my:1}}
                            required
                            fullWidth
                            id="first_name"
                            label="First Name"
                            name="firstName"
                            value={firstName}
                            type="text"
                            placeholder="First Name"
                            onChange={(e) => setFirstName(e.target.value)}
                            autoFocus
                            error={!!errorFirstName}
                            helperText={errorFirstName ? "First name is required" : ""}
                        />

                        <TextField
                        sx={{my:1}}
                            fullWidth
                            id="last_name"
                            label="Last Name"
                            name="lastName"
                            value={lastName}
                            type="text"
                            placeholder="Last Name"
                            onChange={(e) => setLastName(e.target.value)}
                        />

                            <TextField
                            sx={{my:1}}
                            fullWidth
                                required
                                id="email"
                                label="Email"
                                name="email"
                                value={email}
                                type="email"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                error={!!errorEmail}
                                helperText={errorEmail ? "Email is required" : ""}
                            />
                            
                            <FormControl fullWidth sx={{my:1}}>
                              <InputLabel id="demo-select-small-label">Region</InputLabel>
                              <Select
                                fullWidth
                                required
                                labelId="demo-select-small-label"
                                placeholder="Region"
                                id="demo-select-small"
                                value={city}
                                label="Region"
                                onChange={(e) => setCity(e.target.value)}
                                error={!!errorCity}
                                helperText={errorCity? "Region is required" : ""}
                              >
                                <MenuItem value={'Adamawa'}>Adamawa</MenuItem>
                                <MenuItem value={'Center'}>Center</MenuItem>
                                <MenuItem value={'East'}>East</MenuItem>
                                <MenuItem value={'Extreme North'}>Extreme North</MenuItem>
                                <MenuItem value={'North'}>North</MenuItem>
                                <MenuItem value={'Northwest'}>Northwest</MenuItem>
                                <MenuItem value={'Littoral'}>Littoral</MenuItem>
                                <MenuItem value={'South'}>South</MenuItem>
                                <MenuItem value={'Southwest'}>Southwest</MenuItem>
                                <MenuItem value={'West'}>West</MenuItem>
                              </Select>
                            </FormControl>

                            <FormControl fullWidth error={!!errorGender} sx={{my:1}}>
                                <InputLabel id="outlined-error-helper-text1">Sexe</InputLabel>
                                <Select
                                    labelId="outlined-error-helper-text1"
                                    value={gender}
                                    label="Sexe"
                                    name="gender"
                                    onChange={(e) => setGender(e.target.value)}
                                    error={!!errorGender}
                                    helperText={errorGender}
                                >
                                    <MenuItem value={'F'}>F</MenuItem>
                                    <MenuItem value={'M'}>M</MenuItem>
                                </Select>
                                {errorGender && <p style={{ color: 'red' }}>Gender is required</p>}
                            </FormControl>


                            <FormControl fullWidth sx={{my:1}}>
                                <InputLabel id="outlined-error-helper-text">Business category</InputLabel>
                                <Select
                                    labelId="outlined-error-helper-text"
                                    multiple
                                    value={business}
                                    name="business"
                                    required
                                    label="Business category"
                                    onChange={handleChange2}
                                    renderValue={(selected) => selected.map(id => {
                                        const selectedItem = categories.find(category => category._id === id);
                                        return selectedItem ? selectedItem.name : '';
                                    }).join(', ')}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200,
                                                width: 250,
                                            },
                                        },
                                        // Prevent the popover from closing when a selection is made
                                        disableAutoFocusItem: true,
                                    }}
                                >
                                    {categories.map((category) => (
                                        <MenuItem key={category._id} value={category._id}>
                                            <Checkbox checked={business.indexOf(category._id) > -1} />
                                            <ListItemText primary={category.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {business.includes('66d3230cc0d163a73e381b49') && (
                              <TextField
                                fullWidth
                                id="otherBusiness"
                                label="Other Business"
                                name="otherBusiness"
                                value={otherBusiness}
                                type="text"
                                placeholder="Enter your business manually"
                                onChange={(e) => setOtherBusiness(e.target.value)}
                            />
                            )}

                            <FormControl fullWidth sx={{my:1}}>
                              <InputLabel id="outlined-error-helper-text">Interest Center</InputLabel>
                              <Select
                                  labelId="outlined-error-helper-text"
                                  multiple
                                  value={interest}
                                  name="interest"
                                  required
                                  label="Interest Center"
                                  onChange={handleChange}
                                  renderValue={(selected) => selected.map(id => {
                                      const selectedItem = interestCenter.find(int => int._id === id);
                                      return selectedItem ? selectedItem.name : '';
                                  }).join(', ')}
                                  MenuProps={{
                                      PaperProps: {
                                          style: {
                                              maxHeight: 200,
                                              width: 250,
                                          },
                                      },
                                      // Prevent the popover from closing when a selection is made
                                      disableAutoFocusItem: true,
                                  }}
                              >
                                  {interestCenter.map((int) => (
                                      <MenuItem key={int._id} value={int._id}>
                                          <Checkbox checked={interest.indexOf(int._id) > -1} />
                                          <ListItemText primary={int.name} />
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                          <TextField
                          sx={{my:1}}
                            margin="dense"
                            required
                            fullWidth
                            name="dateOfBirth"
                            label="Date of Birth"
                            value={dateOfBirth}
                            type="date"
                            onChange={handleDateChange}
                            error={!!errorDateOfBirth}
                            helperText={errorDateOfBirth ? "Date of birth cannot be in the future" : ""}
                            inputProps={{ max: today }} // Set max date to today
                            InputLabelProps={{
                                shrink: true, // Force the label to shrink
                            }}
                        />
                    <FormControl variant="outlined" fullWidth sx={{my:1}} error={!!errors.password}>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                    </InputAdornment>
                    }
                    label="Password"
                    name='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!errors.password}
                    helperText={errors.password}
                />
                {errors.password && <p style={{ color: 'red' }}>Password is required</p>}
            </FormControl>

            <FormControl variant="outlined" fullWidth error={!!errors.re_password} sx={{my:1}}>
                <InputLabel htmlFor="outlined-adornment-password2">Retype password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password2"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Retype password"
                    name='re_password'
                    value={re_password}
                    onChange={(e) => setRePassword(e.target.value)}
                    error={!!errors.re_password}
                    helperText={errors.re_password}
                />
                {errors.re_password && <p style={{ color: 'red' }}>Password is does not match</p>}
            </FormControl>

            <TextField
                            required
                            fullWidth
                            sx={{my:1}}
                            id="language"
                            label="Language"
                            name="language"
                            value={language}
                            type="text"
                            placeholder="Français"
                            onChange={(e) => setLanguage(e.target.value)}
                            autoFocus
                            error={!!errorLanguage}
                            helperText={errorLanguage ? "Language is required" : ""}
                        />
                      </div>
                    )}
                    </Box>
                    
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleNext} 
                        //  disabled={!isChecked} 
                        disabled={isDisabled || !isChecked}
                        >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
            </Box>
        </Container>
    </ThemeProvider>
  );
}
