// ChiefAgentDashboard.js
import React from 'react';
import AgentTemplate from './AgentTemplate';
import Withdraws from '../../components/withdraws';

const AgentWithdraw = () => {
  return (
    <AgentTemplate>
      <Withdraws />
    </AgentTemplate>
  );
};

export default AgentWithdraw;
