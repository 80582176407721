import axios from 'axios';
import API_URLS from './api';

export const withdrawalService = async (withdrawData, token) => {
  const response = await axios.post(
    API_URLS.withdrawal,
    {
      amount: withdrawData.amount,
      payment_method: withdrawData.withdrawMethod,
      telephone: withdrawData.withdrawNumber,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
