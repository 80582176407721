// AgentComponent.js
import React, { useRef, useState, useEffect } from 'react';
import CustomAccordion from '../../components/accordion';
import AgentTemplate from './AgentTemplate';

const NewClient = () => {
  const clientRef = useRef(null);
  const [clientReferral, setClientReferral] = useState('');

    useEffect(() => {

    const storedToken = JSON.parse(localStorage.getItem('token'));
    // console.log(storedToken);
    setClientReferral(storedToken.data.referral_code[0].code);
    // console.log(storedToken.data.referral_code[0].code);
  }, []);

  const handleCopy = () => {
    const textToCopy = clientRef.current.textContent;
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert('Referral code copied to clipboard!');
    }).catch(err => {
      // console.error('Failed to copy text: ', err);
    });
  };

  return (
    <AgentTemplate>
        <CustomAccordion
        defaultExpanded={true}
        summaryText="Client Referral Code"
        detailContent={
        <>
          <h4>Share your referral code to clients to register under your name.</h4>
          <p>Your Referral code is: <strong ref={clientRef}>{clientReferral}</strong></p>
        </>
        }
        onCopy={handleCopy}
        />
    </AgentTemplate>
    
  );
};

export default NewClient;
