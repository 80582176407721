import React from 'react';
import ClientTemplate from './ClientTemplate';
import FeedbackComponenent from '../../components/Feedback';

const ClientFeedback = () => {
return (
  <ClientTemplate>
    <FeedbackComponenent />
  </ClientTemplate>
);
};
export default ClientFeedback;

