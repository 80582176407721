import React from 'react';

const TermsPage = () => {
  return (
    <div style={{ margin: '0 auto', maxWidth: '800px', padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', color: '#333' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px', fontSize: '2em', color: '#2c3e50' }}>Terms and Conditions of Use</h1>

      <h2>1. Introduction</h2>
      <p>
        Welcome to INTERSTATION. By using our App, you agree to comply with these terms and conditions. Please read them carefully before you start using our services.
      </p>

      <h2>2. Description of the Services</h2>
      <p>
        The INTERSTATION platform is a contact-sharing application. Users have 500 contacts when they open their accounts and between 25 and 50 contacts per day. The distribution of the platform is done through an account prioritization process (Representative account – Agent Manager account – Agent account – Client account).
      </p>

      <h2>3. Collection and Use of Personal Data</h2>
      <p>
        By using INTERSTATION, you agree that we may collect and use your personal data, including your telephone contact, in connection with the provision of our services. This data may be shared with other users of the application depending on their sector of activity or their field of activity. We are committed to respecting the confidentiality of your data and using it only in connection with the services provided by the application.
      </p>

      <h2>4. Consent</h2>
      <p>
        By using this app, you expressly consent to the collection, use, and sharing of your contacts with other users in accordance with these terms and conditions. By using this application, you agree to the general policy of use applicable to a Customer account:
      </p>
      <ul>
        <li>You expressly consent to the collection, use, and sharing of your contacts with other users in accordance with these terms and conditions.</li>
        <li>To access the platform, you unilaterally and consensually agree to pay a subscription fee of 1,000 CFA francs (payable once) and a monthly subscription fee of 1,000 CFA francs.</li>
        <li>By accessing the platform, you unilaterally and consensually agree that your phone number may be shared with other users of the platform.</li>
        <li>You agree unilaterally and consensually to receive telephone numbers on a daily basis.</li>
        <li>You agree unilaterally and consensually to receive a WhatsApp status auto-reading bot.</li>
        <li>You agree to use the platform for purely professional purposes (for the prospecting of your products or services, for the purchase of other users' products or services, for the sale of your products or services). Any other use of this platform is prohibited, accounts engaging in such acts will be banned from the platform for life.</li>
      </ul>

      <h2>5. Responsibilities of the User</h2>
      <p>
        As a user of the app, you agree to use the app for purely business purposes. That is to say, to use the contacts that will be given to you by the application for the prospection and sale of your products or services, as well as the purchase of services or products from other users of the platform. By interacting in the platform via a Representative, Agent, or Agent account, you are responsible for the morality of the accounts opened by you. Any deviant use of the platform by the accounts opened through you will be directly attributable to you.
      </p>

      <h2>6. Intellectual Property Rights</h2>
      <p>
        All intellectual property rights relating to the INTERSTATION application and its content are the exclusive property of the publisher of the application. You agree not to reproduce, duplicate, copy, or exploit any portion of the Application without our prior written permission.
      </p>

      <h2>7. Changes to the Terms and Conditions</h2>
      <p>
        INTERSTATION reserves the right to modify these terms and conditions at any time. The changes will come into effect as soon as they are published on the app. It is your responsibility to review the terms and conditions regularly to stay informed of updates.
      </p>

      <h2>8. Termination</h2>
      <p>
        We reserve the right to terminate or suspend your access to the App at any time, without notice, in the event of a breach of these terms and conditions.
      </p>

      <h2>9. Limitation of Liability</h2>
      <p>
        INTERSTATION shall not be liable for any indirect, special or consequential damages resulting from the use of or inability to use the application. In addition, the platform cannot be held responsible for any delay or delay in the payment of commissions for distribution accounts (Representative account, Agent Manager Account, and Agent account).
      </p>

      <h2>10. Applicable law</h2>
      <p>
        These terms and conditions are governed by the laws of the country in which the application is made. Any dispute arising out of these terms and conditions shall be subject to the exclusive jurisdiction of the competent courts.
      </p>

      <h2>11. Contact</h2>
      <p>
        If you have any questions about these terms and conditions, please contact us at: interstation.app@gmail.com
      </p>
    </div>
  );
};

export default TermsPage;
