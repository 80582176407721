import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import axios from 'axios';
import Box from '@mui/material/Box';
import AppWidgetSummary from '../../components/app-widget-summary';
import AgentTemplate from './AgentTemplate';
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import Clients from '../../components/clients';

const AgentDashboard = () => {
  const [userData, setUserData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [userBalance, setUserBalance] = useState(0);
  const[bearerToken, setBearerToken] = useState('');
  const [clientCount, setClientCount] = useState('')
  const [totalActivation, setTotalActivation] = useState(0);



  const fetchUsers = async (token) => {
    try {
      const response = await axios.get('https://interstation.ibrahimsherif.com/api/referrals', {
        headers: {
          Authorization: `Bearer ${token}`, // Use the actual token value here
        },
      });
      console.log(response.data)
      setUserData([response.data]);

      const users = response.data.data; 
      users.forEach((user, index) => {
      localStorage.setItem(`user_${index}`, JSON.stringify(user));
    });
    } catch (error) {
      // console.error('Error fetching users:', error);
    }
  };

  const fetchDetails = async (token) => {
    try {
      const response = await axios.get('https://interstation.ibrahimsherif.com/api/details', {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });
      setUserDetails([response.data]);
      setUserBalance(response.data.data.balance);
      setTotalActivation(response.data.data.activations);
      setClientCount(response.data.data.number_of_clients);
      console.log(response.data)
    } catch (error) {
      // console.error('Error fetching details:', error);
    }
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('token'));
    if (storedData) {
      const token = storedData.data.token;
      setBearerToken(token);
      fetchUsers(token); // Pass the token directly to fetchUsers
      fetchDetails(token);
    }
  }, []); 

  return (
    <AgentTemplate>
    <Grid container spacing={3}>

    <Grid size={{ xs: 12, sm:6, md: 6 }}>
        <AppWidgetSummary
        title="Clients"
        total={clientCount}
        color="info"
        // icon={<img alt="icon" src="/images/ic_glass_users.png" />}
        icon={<PeopleIcon  sx={{ color: "primary", fontSize: 60 }} />}
        />
    </Grid>

    <Grid size={{ xs: 12, sm:6, md: 6 }}>
        <AppWidgetSummary
        title="Balance"
        total={`${userBalance} FCFA`}
        color="error"
        icon={<AccountBalanceWalletIcon color="action" sx={{ fontSize: 50 }} />}
        />
    </Grid>
    </Grid>

    <Grid size={{ xs: 12, sm:6, md: 6 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Gauge
              value={Math.min(totalActivation, 100)} // Ensure value does not exceed 100
              width={250}  // Larger size for better visibility
              height={200}
              valueMax={100}
              startAngle={-110}
              endAngle={110}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 24, // Slightly larger font size
                  transform: 'translate(0px, 10px)', // Center the text better
                },
              }}
              text={({ value, valueMax }) => `${value} / ${valueMax}`} // Display value in "X / Y" format
            />
            <Typography variant="h6" align="center">
              Daily Activation
            </Typography>
          </Box>
    </Grid>
    <Clients />
    </AgentTemplate>
  );
  
};

export default AgentDashboard;

