import React from 'react';

import ClientTemplate from './ClientTemplate';
import Targeting from '../../components/Targeting';


const ClientTargeting = () => {

  return (
    <ClientTemplate>
      <Targeting />
    </ClientTemplate>
  );
};

export default ClientTargeting;
