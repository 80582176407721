import * as React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import RepresentativeListItems from './RepresentativeListItems';


const RepresentativeTemplate = ({ children }) => {

    return (
    <Header list={RepresentativeListItems} role={"Representative"}>
        {children}
        <Footer/>
    </Header>
    );
};

export default RepresentativeTemplate;
