// ChiefAgentDashboard.js
import React from 'react';
import ChiefAgentTemplate from './ChiefAgentTemplate';
import Targeting from '../../components/Targeting';

const ChiefAgentTarget = () => {
  return (
    <ChiefAgentTemplate>
      <Targeting />
    </ChiefAgentTemplate>
  );
};

export default ChiefAgentTarget;
