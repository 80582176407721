import * as React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import AgentListItems from './AgentListItems';


const AgentTemplate = ({ children }) => {

    return (
    <Header list={AgentListItems} role={"Agent"}>
        {children}
        <Footer/>
    </Header>
    );
};

export default AgentTemplate;
