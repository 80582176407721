// components/AuthCheck.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Box, Button, Typography } from '@mui/material';

const AuthCheck = ({ children }) => {
  const navigate = useNavigate();
  const [isReAuthenticated, setIsReAuthenticated] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const lastAuthTime = localStorage.getItem('lastAuthTime');
    const currentTime = Date.now();
    const authThreshold = 2 * 60 * 1000; // 2 minutes

    if (!lastAuthTime || currentTime - lastAuthTime > authThreshold) {
      // Open modal to request re-authentication if the threshold is exceeded
      setOpenModal(true);
    } else {
      setIsReAuthenticated(true); // Grant access if within 2 minutes of last auth
    }
  }, []);

  const handleReAuthenticate = () => {
    // Redirect to login to re-authenticate
    navigate('/login');
  };

  const handleCancel = () => {
    // Navigate back to the previous page if reauthentication is canceled
    navigate(-1);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCancel}
        aria-labelledby="reauthenticate-modal-title"
        aria-describedby="reauthenticate-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: 'center',
        }}>
          <Typography id="reauthenticate-modal-title" variant="h6">
            Re-authentication Required
          </Typography>
          <Typography id="reauthenticate-modal-description" sx={{ mt: 2 }}>
            Please re-authenticate to access this page.
          </Typography>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" color="primary" onClick={handleReAuthenticate}>
              Re-authenticate
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      {isReAuthenticated && children}
    </>
  );
};

export default AuthCheck;
