// services/apiConfig.js
const API_BASE_URL = 'https://interstation.ibrahimsherif.com/api';

export const API_URLS = {
  contacts: `${API_BASE_URL}/contacts`, // retrieve contacts to diplay from the backend(get)
  download_contacts: `${API_BASE_URL}/download_contacts`, // download contacts from the backend(get)
  recover_contacts: `${API_BASE_URL}/recover_contacts`, // download contacts from the backend(get)
  categories: `${API_BASE_URL}/categories`, // retrieve categories to diplay from the backend(get)
  interests: `${API_BASE_URL}/interests`, // retrieve interests to diplay from the backend(get)
  register0: `${API_BASE_URL}/register/0`, // 1st step registration api (post)
  register1: `${API_BASE_URL}/register/1`, // 2nd step registration api(post)
  register2: `${API_BASE_URL}/register/2`, // 3rd step registration api(post)
  login: `${API_BASE_URL}/login`, // login api(post)
  reset_password: `${API_BASE_URL}/reset_password`, // reset password(post)
  forgot_password: `${API_BASE_URL}/forgot_password`, // password forgotten(post)
  referrals: `${API_BASE_URL}/referrals`, // retrieve referrals(get)
  details: `${API_BASE_URL}/details`, // retrieve details(get)
  withdrawal: `${API_BASE_URL}/withdrawal`, // make withdrawals(post)
};

export default API_URLS;
