import RepresentativeTemplate from './ReprentativeTemplate';
import React, { useState, useEffect } from 'react';
import {  TableCell,TableRow, Paper } from '@mui/material';
import axios from 'axios';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const columns = [
  { field: 'telephone', headerName: 'Telephone', width: 100 },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 200,
    valueGetter: (value, row) => `${row.first_name || ''} ${row.last_name || ''}`,
  },
  { field: 'email', headerName: 'Email', width: 130 },
  { field: 'age', headerName: 'Age', type: 'number', width: 60, },
  { field: 'city', headerName: 'City',  width: 80, },
  { field: 'gender', headerName: 'Gender', width: 60, },
  { field: 'business_categories', headerName: 'Business Categories', width: 200, },
  { field: 'interests', headerName: 'Interests', width: 200, },
  
];  

const paginationModel = { page: 0, pageSize: 5 };

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }

const RepresentativeAgents = () => {

  const [userData, setUserData] = useState([]);
  const [agentsData, setAgentsData] = useState([]);
  const [row, setRow] = useState([]);
  const[bearerToken, setBearerToken] = useState('');


  const fetchUsers = async (token) => {
    try {
      const response = await axios.get('https://interstation.ibrahimsherif.com/api/users', {
        headers: {
          Authorization: `Bearer ${token}`, // Use the actual token value here
        },
      });
      setUserData([response.data]);
      // setUserData([response.data]);
      // setRow(response.data.data)
    } catch (error) {
    }
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('token'));
    if (storedData) {
      const token = storedData.data.token;
      setBearerToken(token);
      fetchUsers(token);
    }
  }, []);
  // const agentsData = userData
  //     .flatMap(user => user.data)
  //     .filter(user => user.role === "agent");

  useEffect(() => {
    const agentsData = userData
      .flatMap(user => user.data)
      .filter(user => user.role === "agent");
      setAgentsData(agentsData)
    setRow(agentsData);
  }, [userData]);

  return (
    <RepresentativeTemplate>
    <h2>Agents</h2>
        {agentsData.length > 0 ? (
          <Paper sx={{ height: 400, width: '100%' }}>
          
          <DataGrid 
          slots={{
              toolbar: CustomToolbar,
            }}
            // rows={rows}
            rows={row}
            columns={columns}
            initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={[5, 10, 25]}
            getRowId={(row) => row.telephone}
            // checkboxSelection
            sx={{ border: 0 }}
            />
          </Paper>
                    ) : (
                    <TableRow>
                      <TableCell colSpan={8}>No agents found</TableCell>
                    </TableRow>
                  )}
        </RepresentativeTemplate>
  );
  
};

export default RepresentativeAgents;

