import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import axios from 'axios';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';



const Agents = () => {
  const [userData, setUserData] = useState([]);
  const [row, setRow] = useState([]);
  const [bearerToken, setBearerToken] = useState('');
  const navigate = useNavigate();

  const columns = [
    {
        field: 'fullName',
        headerName: 'Full Name',
        width: 150,
        valueGetter: (value, row) => `${row.first_name || ''} ${row.last_name || ''}`,
      },
  { field: 'telephone', headerName: 'Telephone', width: 100 },
  { field: 'email', headerName: 'Email', width: 130 },
  { field: 'age', headerName: 'Age', type: 'number', width: 60 },
  { field: 'city', headerName: 'City', width: 100 },
  { field: 'gender', headerName: 'Gender', width: 100 },
  { field: 'daily_activation', headerName: 'Daily Activations', width: 150 },
  { field: 'total_activation', headerName: 'Total Activations', width: 150 },
//   { field: 'business_categories', headerName: 'Business Categories', width: 170 },
//   { field: 'interests', headerName: 'Interests', width: 170 },
  {
    field: 'view',
    headerName: 'View Details',
    width: 150,
    sortable: false,
    renderCell: (params) => {
      
      const handleViewClick = () => {
        // Navigate to the route with the telephone as id
        navigate(`/chief-agent-dashboard/${params.row._id}`);
      };

      return (
        <IconButton color="primary" onClick={handleViewClick}>
          <VisibilityIcon />
        </IconButton>
      );
    },
  },
];

const paginationModel = { page: 0, pageSize: 5 };

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

  const fetchUsers = async (token) => {
    try {
      const response = await axios.get('https://interstation.ibrahimsherif.com/api/referrals', {
        headers: {
          Authorization: `Bearer ${token}`, // Use the actual token value here
        },
      });
      setUserData(response.data.data); // Assuming the data structure
      localStorage.setItem('userData', JSON.stringify(response.data.data));
    } catch (error) {
    //   console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('token'));
    if (storedData) {
      const token = storedData.data.token;
      setBearerToken(token);
      fetchUsers(token);
    }
  }, []);

  useEffect(() => {
    const agentsData = userData.filter(user => user.role === "agent");
    setRow(agentsData);
  }, [userData]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <h2>Agents</h2>
      </div>

      <Paper sx={{ height: 400, width: '100%' }}>
        <DataGrid
          slots={{
            toolbar: CustomToolbar,
          }}
          rows={row}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10, 25]}
          getRowId={(row) => row._id}
        //   getRowId={(row) => row.telephone}
          sx={{ border: 0 }}
        />
      </Paper>
    </div>
  );
};

export default Agents;
