// ChiefAgentDashboard.js
import React from 'react';
import AgentTemplate from './AgentTemplate';
import Targeting from '../../components/Targeting';

const AgentTarget = () => {
  return (
    <AgentTemplate>
      <Targeting />
    </AgentTemplate>
  );
};

export default AgentTarget;
