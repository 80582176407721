// AgentComponent.js
import React, { useRef, useEffect, useState } from 'react';
import CustomAccordion from '../../components/accordion';
import ChiefAgentTemplate from './ChiefAgentTemplate';

const NewAgent = () => {
  const agentRef = useRef(null);
  const clientRef = useRef(null);

  const [clientReferral, setClientReferral] = useState('');
    const [agentReferral, setAgentReferral] = useState('');

  useEffect(() => {

    const storedToken = JSON.parse(localStorage.getItem('token'));
    setClientReferral(storedToken.data.referral_code[0].code);
    setAgentReferral(storedToken.data.referral_code[1].code);
  }, []);

  const handleCopy = () => {
    
    const textToCopy = agentRef.current.textContent;
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert('Referral code copied to clipboard!');
    }).catch(err => {
      // console.error('Failed to copy text: ', err);
    });
  };
  const handleCopy2 = () => {
    const textToCopy = clientRef.current.textContent;
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert('Referral code copied to clipboard!');
    }).catch(err => {
      // console.error('Failed to copy text: ', err);
    });
  };

  return (
    <ChiefAgentTemplate>
        <CustomAccordion
        defaultExpanded={true}
      summaryText="Agent Referral Code"
      detailContent={
        <>
          <h4>Share your referral code to agents to register under your name.</h4>
          <p>Your Referral code is: <strong ref={agentRef}>{agentReferral}</strong></p>
        </>
      }
      onCopy={handleCopy}
    />
        <CustomAccordion
        defaultExpanded={false}
        summaryText="Client Referral Code"
        detailContent={
        <>
          <h4>Share your referral code to clients to register under your name.</h4>
          <p>Your Referral code is: <strong ref={clientRef}>{clientReferral}</strong></p>
        </>
        }
        onCopy={handleCopy2}
        />
    </ChiefAgentTemplate>
    
  );
};

export default NewAgent;
