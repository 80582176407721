const ChiefAgentListItems = [
  { to: '/chief-agent-dashboard', primary: 'Dashboard', icon: 'PeopleIcon' },
  { to: '/new-agent', primary: 'New Agent', icon: 'GroupAddIcon' },
  { to: '/chief-agent-contacts', primary: 'Contacts', icon: 'ContactsIcon' },
  { to: '/chief-agent-target', primary: 'Targeting', icon: 'TuneIcon' },
  { to: '/chief-agent-withdraws', primary: 'Withdraws', icon: 'PaymentIcon' },
  { to: '/chief-agent-report', primary: 'Report', icon: 'AssessmentIcon' },
];

export default ChiefAgentListItems;
