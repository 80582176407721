import * as React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ClientListItems from './ClientListItems';

const ClientTemplate = ({ children }) => {

    return (
    <Header list={ClientListItems} role={"Client"}>
        {children}
        <Footer/>
    </Header>
    );
};

export default ClientTemplate;
