import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Modal } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';
import Select from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { OutlinedInput } from '@mui/material';
import { InputAdornment } from '@mui/material';
// import "../../SignupPage.css";
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { withdrawalService } from '../services/withdrawalService';



const steps = ['Withdraw Information', 'Confirmation'];

const defaultTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: '#B82956',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#B82956',
          },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: '#B82956',
              borderWidth: '0.15rem',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#B82956',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          fontSize: '1rem',
        },
      },
    },
  },
});

export default function Withdraws() {
  
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [bearerToken, setBearerToken] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [withdrawData, setWithdrawData] = useState({
    withdrawNumber: '',
    amount: '',
    withdrawMethod: '',
  });
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'telephone' && value.length > 9) {
      return; // Prevent input if length exceeds 9
    }
    setWithdrawData({
      ...withdrawData,
      [name]: value
    });
  };

  const [errors, setErrors] = useState({
    amount: false,
    withdrawMethod: false,
    withdrawNumber: false,
  });

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('token'));
    if (storedData) {
      setBearerToken(storedData.data.token);
    }
  }, []); // This effect runs once when the component mounts

  const handleConfirm = () =>{
    const validationErrors = validateWithdrawData(withdrawData);
    setErrors(validationErrors);
    if (!Object.values(validationErrors).includes(true)) {
      try {
        setOpenModal(true);
      } catch (error) {
        setErrors(validationErrors);
      }
    }
  }
  const handleCancel = () =>{
    setOpenModal(false);
  }
  const handleNext = async () => {
    toggleLoading(true);

    const validationErrors = validateWithdrawData(withdrawData);
    setErrors(validationErrors);

    if (!Object.values(validationErrors).includes(true)) {
      try {
        await withdrawalService(withdrawData, bearerToken);
        setActiveStep(steps.length); // Skip to "Withdraw Completed"
      } catch (error) {
        setError(error.response?.data?.message || 'An error occurred during withdrawal.');
      }
    }

    toggleLoading(false);
  };

  const validateWithdrawData = (data) => ({
    amount: data.amount === '',
    withdrawMethod: data.withdrawMethod === '',
    withdrawNumber: data.withdrawNumber === '',
  });

  const toggleLoading = (isLoading) => {
    setLoading(isLoading);
    setIsConfirmDisabled(isLoading);
  };

  const handleLogin = () => {
    navigate('/chief-dashboard');
  };

  return (
    <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box sx={{ width: '100%', marginTop: '80px' }}>
        {loading && (
        <Box sx={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
          <LinearProgress />
        </Box>
      )}
            <Typography component="h1" variant="h5" style={{ marginBottom: "20px", textAlign:'center' }}>
                Withdraw Form
            </Typography>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1, justifyContent: 'center'}}>
                    Withdraw Completed
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleLogin}>Go to Dashboard</Button>
                </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Box sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    {activeStep === 0 && (
                      <>
                        <FormControl fullWidth margin="normal" error={errors.withdrawMethod}>
                          <InputLabel id="withdraw-method">Withdraw Method*</InputLabel>
                          <Select
                            labelId="withdraw-method"
                            id="withdraw-method-select"
                            value={withdrawData.withdrawMethod}
                            label="Withdraw Method*"
                            name="withdrawMethod"
                            onChange={handleChange}
                          >
                            <MenuItem value={"Momo"}>Mobile Money</MenuItem>
                            <MenuItem value={"OM"}>Orange Money</MenuItem>
                          </Select>
                          {errors.withdrawMethod && <p style={{ color: 'red' }}>Withdraw method is required</p>}
                        </FormControl>
                        
                        <FormControl fullWidth sx={{ mt: 3 }} error={errors.withdrawNumber}>
                          <InputLabel htmlFor="outlined-adornment-amount">Withdraw number</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">+ 237</InputAdornment>}
                            required
                            margin="normal"
                            name="withdrawNumber"
                            label="Phone Number"
                            type="number"
                            value={withdrawData.withdrawNumber}
                            onChange={handleChange}
                            error={errors.withdrawNumber}
                            helperText={errors.withdrawNumber ? 'Withdraw number is required' : ''}
                            inputProps={{ maxLength: 9 }} // Limit input to 9 digits
                            autoFocus
                          />
                          {errors.withdrawNumber && <p style={{ color: 'red' }}>Withdraw number is required</p>}
                        </FormControl>

                        <FormControl fullWidth sx={{ mt: 3 }} error={errors.amount}>
                          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">XAF</InputAdornment>}
                            required
                            margin="normal"
                            name="amount"
                            label="Amount"
                            type="number"
                            value={withdrawData.amount}
                            onChange={handleChange}
                            error={errors.amount}
                            helperText={errors.amount ? 'Amount is required' : ''}
                            inputProps={{ maxLength: 9 }} // Limit input to 9 digits
                            autoFocus
                          />
                          {errors.amount && <p style={{ color: 'red' }}>Withdraw amount is required</p>}
                        </FormControl>
                        {error && <Typography color="error" variant="body2" sx={{ mt: 2 }}>{error}</Typography>}
                      </>
                    )}
                    </Box>
                    
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button type="submit" onClick={handleConfirm} disabled={isConfirmDisabled}>
                        {activeStep === steps.length - 1 ? 'Next' : 'Submit'}
                        </Button>
                    </Box>
                    <Modal
                      open={openModal}
                      onClose={handleCancel}
                      aria-labelledby="reauthenticate-modal-title"
                      aria-describedby="reauthenticate-modal-description"
                    >
                      <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        textAlign: 'center',
                      }}>
                        <Typography id="reauthenticate-modal-title" variant="h6">
                          Confirm widrawal
                        </Typography>
                        <Typography id="reauthenticate-modal-description" sx={{ mt: 2 }}>
                          Do you validate the withdrawal of {withdrawData.amount} XAF from {withdrawData.withdrawNumber} ?
                        </Typography>
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                          <Button variant="contained" color="primary" onClick={handleNext}>
                            Confirm
                          </Button>
                          <Button variant="outlined" color="secondary" onClick={handleCancel}>
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    </Modal>
                </React.Fragment>
            )}
            </Box>
        </Container>
    </ThemeProvider>
  );
}
