import React from 'react';
import RepresentativeTemplate from './ReprentativeTemplate';
import AppWidgetSummary from '../../components/app-widget-summary';
import Grid from '@mui/material/Grid2';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { PieChart } from '@mui/x-charts/PieChart';
import { Paper } from '@mui/material';

const RepresentativeDashboard = () => {

  return (
    <RepresentativeTemplate>
        <Grid container spacing={4}>
          <Grid size={{ xs: 12, sm:6, md: 3 }}>
        <AppWidgetSummary
        title="Clients"
        total={12}
        color="info"
        icon={<AccountCircleIcon  sx={{ color: "primary", fontSize: 60 }} />}
        />
          </Grid>
          <Grid size={{ xs: 12, sm:6, md: 3 }}>
          <AppWidgetSummary
        title="Agents"
        // total={clientCount}
        total={12}
        color="info"
        icon={<SupervisedUserCircleIcon  sx={{ color: "primary", fontSize: 60 }} />}
        />
          </Grid>
          <Grid size={{ xs: 12, sm:6, md: 3 }}>
          <AppWidgetSummary
        title="Agencies"
        // total={clientCount}
        total={12}
        color="info"
        icon={<SupportAgentIcon  sx={{ color: "primary", fontSize: 60 }} />}
        />
          </Grid>
          <Grid size={{ xs: 12, sm:6, md: 3 }}>
          <AppWidgetSummary
        title="XAF"
        // total={clientCount}
        total={12}
        color="info"
        icon={<AccountBalanceWalletIcon  sx={{ color: "primary", fontSize: 60 }} />}
        />
          </Grid>
        </Grid>
        <Paper sx={{mt: 5}}>
        <PieChart
        sx={{p: 5}}
      series={[
        {
          data: [
            { id: 0, value: 10, label: 'series A' },
            { id: 1, value: 15, label: 'series B' },
            { id: 2, value: 20, label: 'series C' },
          ],
        },
      ]}
      width={400}
      height={300}
    />
        </Paper>
    </RepresentativeTemplate>
  );
  
};

export default RepresentativeDashboard;

