import React from 'react';

const NotFound = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#f8f9fa',
      color: '#343a40',
      textAlign: 'center',
    },
    heading: {
      fontSize: '3rem',
      marginBottom: '1rem',
    },
    paragraph: {
      fontSize: '1.5rem',
      marginBottom: '1rem',
    },
    button: {
      padding: '0.5rem 1rem',
      fontSize: '1rem',
      color: '#fff',
      backgroundColor: '#dc3545',
      border: 'none',
      borderRadius: '0.25rem',
      cursor: 'pointer',
    },
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404 - Page Not Found</h1>
      <p style={styles.paragraph}>The page you are looking for does not exist.</p>
      <button style={styles.button} onClick={goBack}>Go Back</button>
    </div>
  );
};

export default NotFound;
