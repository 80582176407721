import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { IconButton } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import { VisibilityOff } from '@mui/icons-material';
import {
    Paper,
  } from '@mui/material';
  import Avatar from '@mui/material/Avatar';

const defaultTheme = createTheme();

export default function SignIn() {
  const [password, setPassword] = useState('');
  const [telephone, setTelephone] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleOnClick  = () => {
    navigate('/register');
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
      try {
        const response = await axios.post('https://interstation.ibrahimsherif.com/api/login', {
          telephone: telephone,
          password: password,
        });
        setLoading(false);
        const token = response.data;
        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('lastAuthTime', Date.now())
        if(token.data.role === "agent"){
          navigate('/agent-dashboard');
        } else if(token.data.role === "chief-agent"){
          navigate('/chief-agent-dashboard');
        }else if(token.data.role === "client"){
          navigate('/dashboard');
        }else if(token.data.role === "inverse-representative"){
          navigate('/admin-dashboard');
        }
      } catch (error) {
        setLoading(false);
        setError(error.response.data.message);
      }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ width: '100%' }}>
        {loading && (
        <Box sx={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
          <LinearProgress />
        </Box>
      )}
      </Box>
      
      <Box
      sx={{
        // minHeight: { md: '100vh' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: '#f5f5f5',
        px: 2,
        pt: { xs: 5, md:10 }
      }}
    >
    
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          maxWidth: 800,
          width: '100%',
          overflow: 'hidden',
          borderRadius: 2,
        }}
      >
        {/* Left Side - Login Form */}
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            p: 4,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
            <Typography variant="h5" fontWeight="bold">
              Sign In
            </Typography>
            <Avatar
          src={'images/inter-trans.PNG'}
          sx={{
            width: 40,
            height: 40,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
            backgroundColor: '#f4d1b9', // Set the background color to white
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
          </Box>
          <TextField
            fullWidth
            label="Telephone"
            placeholder="Telephone"
            name="telephone"
            variant="outlined"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            type="number"
            sx={{
              mb: 3,
              '& .MuiOutlinedInput-root': {
                bgcolor: '#f5f5f5',
              },
            }}
          />

          {/* <TextField
            fullWidth
            name="password"
                    label="Password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="current-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                              <InputAdornment position="end">
                                  <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                  >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                          </InputAdornment>
                          }
            placeholder="Password"
            variant="outlined"
            sx={{
              mb: 3,
              '& .MuiOutlinedInput-root': {
                bgcolor: '#f5f5f5',
              },
            }}
          /> */}
          <TextField
      fullWidth
      name="password"
      label="Password"
      id="password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      autoComplete="current-password"
      type={showPassword ? 'text' : 'password'}
      placeholder="Password"
      variant="outlined"
      sx={{
        mb: 3,
        '& .MuiOutlinedInput-root': {
          bgcolor: '#f5f5f5',
        },
      }}
      // Wrap the adornment within InputProps
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />

          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{
              mb: 2,
              py: 1.5,
              bgcolor: '#B62D57',
              '&:hover': {
                bgcolor: '#F4D1B9',
                color:'#212121',
              },
            }}
          >
            Sign In
          </Button>
          {error && 
            <Alert variant="outlined" severity="error">
            {error}
            </Alert>
          /* <p style={{ color: 'red' }}>{error}</p> */
          }
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  sx={{
                    color: '#ff4b6e',
                    '&.Mui-checked': {
                      color: '#ff4b6e',
                    },
                  }}
                />
              }
              label="Remember Me"
              sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
            /> */}
            <Link
              href="/password-forgot"
              underline="hover"
              sx={{
                mt: '3',
                color: 'text.secondary',
                fontSize: '0.875rem',
              }}
            >
              Forgot Password
            </Link>
          </Box>
        </Box>

        <Box
          // sx={{
          //   bgcolor: '#B62D57',
          //   color: 'white',
          //   p: 4,
          //   flex: 1,
          //   display: { xs: 'none',  md: 'flex' },
          //   flexDirection: 'column',
          //   alignItems: 'center',
          //   justifyContent: 'center',
          //   textAlign: 'center',
          // }}
          sx={{
        bgcolor: '#B62D57',
        color: 'white',
        p: 4,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
        >
          <Typography variant="h5" component="h3" fontWeight="bold" mb={2} sx={{ display: {'xs': 'none', md:'flex'}}} >
            Welcome to Interstation
          </Typography>
          <Typography variant="body1" mb={3}>
            Don't have an account?
          </Typography>
          <Button
            variant="outlined"
            onClick={handleOnClick}
            sx={{
              color: 'white',
              borderColor: 'white',
              '&:hover': {
                borderColor: 'white',
                bgcolor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            Sign Up
          </Button>
        </Box>
      </Paper>
    </Box>
    </ThemeProvider>
  );
}
