import React from 'react';
import AgentTemplate from './AgentTemplate';
import ContactTable from '../../components/contact-table';

const AgentContact = () => {
  return (
    <AgentTemplate>
      <ContactTable />
    </AgentTemplate>
  );
};

export default AgentContact;
