import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

const defaultTheme = createTheme();

export default function EmailConfirm() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
      try {
        const response = await axios.post('https://interstation.ibrahimsherif.com/api/forgot_password', {
          email: email,
        });
        setLoading(false);
        navigate('/mailsent')
      } catch (error) {
        setLoading(false);
        console.error('Error during login:', error);
        setError(error.response.data.message);
      }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{ width: '100%', marginTop: '80px' }}>
        {loading && (
        <Box sx={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
          <LinearProgress />
        </Box>
      )}
      </Box>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'error.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Password Reset
          </Typography>
          <Typography sx={{my:3}}>
            Enter your email to reset your password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

            <FormControl fullWidth>
                          <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            required
                            margin="dense"
                            name="email"
                            label="Email"
                            value={email}
                            type="text"
                            onChange={(e) => setEmail(e.target.value)}
                            autoFocus
                          />
                        </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
              mt: 2,
              py: 1.5,
              bgcolor: '#B62D57',
              '&:hover': {
                bgcolor: '#F4D1B9',
                color:'#212121',
              }, }}
              >
              Send
            </Button>
            {error && 
            <>
            <p style={{ color: 'red' }}>{error}</p>
              
            </>
            }
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
