import React from 'react';
import RepresentativeTemplate from './ReprentativeTemplate';
import ContactTable from '../../components/contact-table';


const RepresentativeContact = () => {


  return (
    <RepresentativeTemplate>
        <ContactTable />
    </RepresentativeTemplate>
  );
  
};

export default RepresentativeContact;

