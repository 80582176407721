import React from 'react';
import ClientTemplate from './ClientTemplate';
import ContactTable from '../../components/contact-table';

const ClientDashboard = () => {
return (
  <ClientTemplate>
    <ContactTable />
  </ClientTemplate>
);
};
export default ClientDashboard;

