import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { FormControl, Select, MenuItem } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  { field: 'age', headerName: 'Age', type: 'number', width: 90, },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
  },
];

const column = [
    { field: '_id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Interest', width: 130 },
    { headerName: 'Edit', width: 130 },
]   

const paginationModel = { page: 0, pageSize: 5 };

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }

const Reports = () => {
    const [frequency, setFrequency] = useState('');
    const [interestCenter, setInterestCenter] = useState([]);
    const [index, setIndex] = useState('')
    // const navigate = useNavigate();

    const handleRowClick = (params) => {
        setIndex(params.row._id)
        // console.log(index)
        // navigate(`/chief-agent-dashboard/${index}`);
      };

    const handleChange = async (e) => {
        setFrequency(e.target.value)
            try {
              const interestCenterResponse = await axios.get('https://interstation.ibrahimsherif.com/api/interests');
              setInterestCenter(interestCenterResponse.data.data);
            } catch (error) {
              // console.error('Error fetching data:', error);
            }
    }
  return (
    <>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <h2> Agents report</h2>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
            {/* <InputLabel id="demo-simple-select-autowidth-label">Select frequency</InputLabel> */}
            <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
                displayEmpty
                value={frequency}
                onChange={handleChange}
            >
                <MenuItem value="" disabled>
                    <em>Select frequency</em>
                </MenuItem>
                <MenuItem value={10}>Today</MenuItem>
                <MenuItem value={20}>This week</MenuItem>
                <MenuItem value={30}>The month</MenuItem>
            </Select>
            </FormControl>
        </div>
    </div>
    <Paper sx={{ height: 400, width: '100%' }}>
     
      <DataGrid 
      slots={{
          toolbar: CustomToolbar,
        }}
        // rows={rows}
        rows={interestCenter}
        onRowClick={handleRowClick}
        columns={column}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10, 25]}
        getRowId={(row) => row._id}
        // checkboxSelection
        sx={{ border: 0 }}
      />
    </Paper>
    </>
  );
};

export default Reports;
