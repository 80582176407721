import React from 'react'
import Notifications from '../../components/Notifications'
import ClientTemplate from './ClientTemplate'

const ClientNotification = () => {
  return (
    <ClientTemplate> 
        <Notifications />
    </ClientTemplate>
  )
}

export default ClientNotification