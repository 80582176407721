// ChiefAgentDashboard.js
import React from 'react';
import ChiefAgentTemplate from './ChiefAgentTemplate';
import Withdraws from '../../components/withdraws';

const ChiefAgentWithdraw = () => {
  return (
    <ChiefAgentTemplate>
      <Withdraws />
    </ChiefAgentTemplate>
  );
};

export default ChiefAgentWithdraw;
