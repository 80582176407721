// components/CustomAccordion.js
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

const CustomAccordion = ({ summaryText, detailContent, onCopy, defaultExpanded = false }) => {
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <h3>{summaryText}</h3>
      </AccordionSummary>
      <AccordionDetails>
        {detailContent}
      </AccordionDetails>
      <AccordionActions>
        <Button onClick={onCopy}>Copy</Button>
      </AccordionActions>
    </Accordion>
  );
};

export default CustomAccordion;
