// ChiefAgentDashboard.js
import React from 'react';
import ChiefAgentTemplate from './ChiefAgentTemplate';
import Reports from '../../components/reports';

const ChiefAgentReport = () => {
  return (
    <ChiefAgentTemplate>
      <Reports />
    </ChiefAgentTemplate>
  );
};

export default ChiefAgentReport;
