import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Container,
  IconButton,
  MenuItem,
  Select,
  InputLabel
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import Grid from '@mui/material/Grid2';

export default function ProfileEditor() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    region: '',
    language: '',
    email: '',
    dateOfBirth: null,
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dateOfBirth: date,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('https://interstation.ibrahimsherif.com/api/update_user', formData);
      // Handle successful submission (e.g., show a success message)
    } catch (error) {
      // Handle error (e.g., show an error message)
      // console.error('Error submitting form:', error);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'background.paper',
          p: 3,
          borderRadius: 2,
          position: 'relative',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => navigate(-1)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography component="h1" variant="h5">
          Edit Profile
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                value={formData.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              {/* <TextField
                required
                fullWidth
                id="region"
                label="Region"
                name="region"
                value={formData.region}
                onChange={handleChange}
              /> */}
              <InputLabel id="demo-select-small-label">Region</InputLabel>
                              <Select
                                fullWidth
                                required
                                labelId="demo-select-small-label"
                                name='region'
                                placeholder="Region"
                                id="demo-select-small"
                                value={formData.region}
                                label="Region"
                                onChange={handleChange}
                              >
                                <MenuItem value={'Adamawa'}>Adamawa</MenuItem>
                                <MenuItem value={'Center'}>Center</MenuItem>
                                <MenuItem value={'East'}>East</MenuItem>
                                <MenuItem value={'Extreme North'}>Extreme North</MenuItem>
                                <MenuItem value={'North'}>North</MenuItem>
                                <MenuItem value={'Northwest'}>Northwest</MenuItem>
                                <MenuItem value={'Littoral'}>Littoral</MenuItem>
                                <MenuItem value={'South'}>South</MenuItem>
                                <MenuItem value={'Southwest'}>Southwest</MenuItem>
                                <MenuItem value={'West'}>West</MenuItem>
                              </Select>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <TextField
                required
                fullWidth
                id="language"
                label="Language"
                name="language"
                value={formData.language}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date of Birth"
                  value={formData.dateOfBirth}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider> */}
              <TextField
                margin="dense"
                required
                fullWidth
                name="formData.dateOfBirth"
                label="Date of Birth"
                value={formData.dateOfBirth}
                type="date"
                onChange={handleDateChange}
                // renderInput={(params) => <TextField {...params} fullWidth />}
                InputLabelProps={{
                    shrink: true, // Force the label to shrink
                }}
            />

            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ bgcolor: '#B62D57',mt: 3, mb: 2, '&:hover': { bgcolor: '#F4D1B9' } }}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
