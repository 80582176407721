import axios from 'axios';
import API_URLS from './api'; 

export const fetchCategories = async () => {
    const response = await axios.get(API_URLS.categories);
    return response.data.data;
  };
  
  export const fetchInterestCenters = async () => {
    const response = await axios.get(API_URLS.interests);
    return response.data.data;
  };
