import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import { ChiefAgentListItems } from '../pages/ChiefAgent/ChiefAgentListItems';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountPopover from './account-popover';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PaymentIcon from '@mui/icons-material/Payment';
import PeopleIcon from '@mui/icons-material/People';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ContactsIcon from '@mui/icons-material/Contacts';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TuneIcon from '@mui/icons-material/Tune';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import RateReviewIcon from '@mui/icons-material/RateReview';
import Paper from '@mui/material/Paper';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    }));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? theme.palette.secondary.main
         : theme.palette.secondary.main,
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


const defaultTheme = createTheme({
  typography: {
    fontFamily: [
      'ui-sans-serif',
      'system-ui',
      '"sans-serif"',
      'Apple Color Emoji',
      '"Segoe UI Emoji"',
      'Segoe UI Symbol',
      'Noto Color Emoji',
      '"Apple Color Emoji"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#b62d57',
    },
    secondary: {
      main: '#f4d1b9',
    }
  }
});

const icons = {
    PeopleIcon,
    GroupAddIcon,
    ContactsIcon,
    TuneIcon,
    PaymentIcon,
    DashboardIcon,
    SupportAgentIcon,
    SupervisedUserCircleIcon,
    AccountCircleIcon,
    AssessmentIcon,
    RateReviewIcon,
    NotificationsActiveIcon
  };

export default function Header({ children, list, role }) {
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {role}
            </Typography>
            <AccountPopover />
            
            
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} sx={{backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.secondary.main
               : theme.palette.secondary.main,}}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
          {list.map((item, index) => {
                const IconComponent = icons[item.icon];
                return (
                <ListItemButton component={Link} to={item.to} key={index}>
                    <ListItemIcon>
                    <IconComponent />
                    </ListItemIcon>
                    <ListItemText primary={item.primary} />
                </ListItemButton>
                );
            })}
            <Divider sx={{ my: 1 }} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.secondary.main
               : theme.palette.secondary.main,
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Grid size={{ xs: 12 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                {children}
                </Paper>
              </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}