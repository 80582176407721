// ChiefAgentDashboard.js
import React from 'react';
import ChiefAgentTemplate from './ChiefAgentTemplate';
import ContactTable from '../../components/contact-table';

const ChiefAgentContact = () => {
  return (
    <ChiefAgentTemplate>
      <ContactTable />
    </ChiefAgentTemplate>
  );
};

export default ChiefAgentContact;
