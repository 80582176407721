import React, { useState, useEffect } from 'react';
import {
  TextField, InputLabel,FormControl, Select, MenuItem, ListItemText, Checkbox, Box, Button, OutlinedInput, Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchCategories, fetchInterestCenters } from '../services/dataService';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';

const regions = [
  'Adamawa',
  'Center',
  'East',
  'Extreme North',
  'North',
  'Northwest',
  'Littoral',
  'South',
  'Southwest',
  'West',
];


const Targeting = () => {
  const [title, setTitle] = useState('');
  const [business, setBusiness] = useState([]);
  const [city, setCity] = useState([]);
  const [gender, setGender] = useState('');
  const [categories, setCategory] = useState([]);
  const [interest, setInterest] = useState([]);
  const [interestCenter, setInterestCenter] = useState([]);
  const [ageMin, setAgeMin] = useState(0);
  const [ageMax, setAgeMax] = useState(0);
  const [description, setDescription] = useState('');
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    const newErrors = {};

    if (!title.trim()) {
      newErrors.title = 'Title is required';
    }
    if (!city.length || city.every(c => !c.trim())) {
      newErrors.city = 'Region is required';
    }
    if (!business.length || business.every(c => !c.trim())) {
      newErrors.business = 'Business Category is required';
    }
    
    // if (!business.trim()) {
    //   newErrors.business = 'Name is required';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCity = (event) => {
    const {
      target: { value },
    } = event;
    setCity(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesData = await fetchCategories();
        setCategory(categoriesData);
        
        const interestsData = await fetchInterestCenters();
        setInterestCenter(interestsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event) => {
    const {
        target: { value },
    } = event;
    setInterest(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
    );
};
const handleChange2 = (event) => {
  const {
      target: { value },
  } = event;
  setBusiness(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
  );
};

  const handleSubmit = async (event) => {
    

    event.preventDefault();
      
      if (!validateForm()) return;
      const formData = {
        title,
        city,
        gender,
        business,
        interest,
        ageMin,
        ageMax,
        description,
      };
  
      try {
        setLoading(true);
        // await axios.post('https://interstation.ibrahimsherif.com/api/contact_us', formData);
        setLoading(false);
        navigate('/successful')
      } catch (error) {
        console.error("Failed to submit:", error.response);
      }
  }
  return (
    <>
      <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{
        mt: 1,
        mx: 'auto',
        maxWidth: '500px',
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: 'white',
      }}
    >
    {loading && (
        <Box sx={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
          <LinearProgress />
        </Box>
      )}

      <Typography variant="h5" component="h2" align="center" gutterBottom>
        Targeting Form
      </Typography>

      <TextField
      sx={{my:1}}
        margin="dense"
        fullWidth
        required
        name="title"
        label="Targeting title"
        value={title}
        type="text"
        onChange={(e) => setTitle(e.target.value)}
        error={!!errors.title}
        helperText={errors.title}
      />

      <FormControl fullWidth margin="normal" sx={{my:1}}>
      
        <InputLabel id="demo-multiple-name-label">Select your target region(s)</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={city}
          required
          label="Region"
          onChange={handleCity}
          input={<OutlinedInput label="Region" />}
          error={!!errors.city}
          renderValue={(selected) => selected.join(', ')}
        >
          {regions.map((region) => (
            <MenuItem
              key={region}
              value={region}
            >
              <Checkbox checked={city.indexOf(region) > -1} />
              <ListItemText primary={region} />
            </MenuItem>
          ))}
        </Select>
        {errors.city && <small style={{ color: 'red' }}>Region is required</small>}
      </FormControl>

      <FormControl fullWidth margin="normal" sx={{my:1}}>
        <InputLabel id="demo-select-small-label">Preferred gender</InputLabel>
        <Select
          labelId="demo-select-small-label"
          value={gender}
          label="Gender"
          name="gender"
          onChange={(e) => setGender(e.target.value)}
        >
          <MenuItem value={''}>None</MenuItem>
          <MenuItem value={'M'}>Male</MenuItem>
          <MenuItem value={'F'}>Female</MenuItem>
          
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal" sx={{my:1}}>
        <InputLabel id="outlined-error-helper-text">Choose a category of business for your preferred contacts</InputLabel>
        <Select
          labelId="outlined-error-helper-text"
          multiple
          value={business}
          name="business"
          required
          label="Business category"
          onChange={handleChange2}
          error={!!errors.business}
          renderValue={(selected) => selected.map(id => {
            const selectedItem = categories.find(category => category._id === id);
            return selectedItem ? selectedItem.name : '';
          }).join(', ')}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                width: 250,
              },
            },
            disableAutoFocusItem: true,
            
          }}
        >
          {categories.map((category) => (
            <MenuItem key={category._id} value={category._id}>
              <Checkbox checked={business.indexOf(category._id) > -1} />
              <ListItemText primary={category.name} />
            </MenuItem>
          ))}
        </Select>
        {errors.business && <small style={{ color: 'red' }}>Business category is required</small>}
      </FormControl>

      <FormControl fullWidth margin="normal" sx={{my:1}}>
        <InputLabel id="outlined-error-helper-text">Any interest center? Select them to get contacts with similar interests</InputLabel>
        <Select
          labelId="outlined-error-helper-text"
          multiple
          value={interest}
          name="interest"
          label="Interest Center"
          onChange={handleChange}
          
          renderValue={(selected) => selected.map(id => {
            const selectedItem = interestCenter.find(int => int._id === id);
            return selectedItem ? selectedItem.name : '';
          }).join(', ')}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                width: 250,
              },
            },
            disableAutoFocusItem: true,
          }}
        >
          {interestCenter.map((int) => (
            <MenuItem key={int._id} value={int._id}>
              <Checkbox checked={interest.indexOf(int._id) > -1} />
              <ListItemText primary={int.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
      sx={{my:1}}
        margin="dense"
        fullWidth
        name="Age preference"
        label="Minimum age Preference"
        value={ageMin}
        type="number"
        onChange={(e) => setAgeMin(e.target.value)}
      />
      <TextField
      sx={{my:1}}
        margin="dense"
        fullWidth
        name="Age preference"
        label="Maximum age Preference"
        value={ageMax}
        type="number"
        onChange={(e) => setAgeMax(e.target.value)}
      />
      <TextField
      sx={{my:1}}
          label="Enter a description"
          name="description"
          variant="outlined"
          multiline
          rows={6}
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          
        />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Submit
      </Button>
    </Box>
    </>
  );
};

export default Targeting;
