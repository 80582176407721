import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import axios from 'axios';
import AppWidgetSummary from '../../components/app-widget-summary';
// import { useNavigate } from 'react-router-dom';
import ChiefAgentTemplate from './ChiefAgentTemplate';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import Agents from '../../components/agents';
import Clients from '../../components/clients';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid2';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


const Customers = () => {
  const [userData, setUserData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [userBalance, setUserBalance] = useState(0);
  const [bearerToken, setBearerToken] = useState('');
  const [totalActivations, setTotalActivations] = useState(0);
  const [clientCount, setClientCount] = useState(0);
  const [agentCount, setAgentCount] = useState(0);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  const fetchUsers = async (token) => {
    try {
      const response = await axios.get('https://interstation.ibrahimsherif.com/api/referrals', {
        headers: {
          Authorization: `Bearer ${token}`, // Use the actual token value here
        },
      });
      console.log(response.data)
      setUserData([response.data]);

      const users = response.data.data; 
      users.forEach((user, index) => {
      localStorage.setItem(`user_${index}`, JSON.stringify(user));
    });
    } catch (error) {
      // console.error('Error fetching users:', error);
    }
  };

  const fetchDetails = async (token) => {
    try {
      const response = await axios.get('https://interstation.ibrahimsherif.com/api/details', {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });
      setUserDetails([response.data]);
      setUserBalance(response.data.data.balance);
      setClientCount(response.data.data.number_of_clients);
      setAgentCount(response.data.data.number_of_agents);
      setTotalActivations(response.data.data.activations);
      console.log(response.data);
    } catch (error) {
      // console.error('Error fetching details:', error);
    }
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('token'));
    if (storedData) {
      const token = storedData.data.token;
      setBearerToken(token);
      fetchUsers(token); // Pass the token directly to fetchUsers
      fetchDetails(token);
    }
  }, []);

  return (
    <ChiefAgentTemplate>
    <Grid container spacing={3}>
    <Grid size={{ xs: 12, sm:6, md: 3 }}>
        <AppWidgetSummary
        title="Agents"
        total={agentCount}
        color="success"
        icon={<AccountCircleIcon color="primary" sx={{  fontSize: 60 }} />}
        />
    </Grid>

    <Grid size={{ xs: 12, sm:6, md: 3 }}>
        <AppWidgetSummary
        title="Clients"
        total={clientCount}
        color="info"
        icon={<PeopleIcon  sx={{ color: "primary", fontSize: 60 }} />}
        />
    </Grid>

    <Grid size={{ xs: 12, sm:6, md: 3 }}>
        <AppWidgetSummary
        title="Activations today"
        total={totalActivations}
        color="warning"
        icon={<ToggleOnIcon color="success" sx={{ color: "primary", fontSize: 60 }} />}
        />
    </Grid>

    <Grid size={{ xs: 12, sm:6, md: 3 }}>
        <AppWidgetSummary
        title="FCFA"
        total={`${userBalance} `}
        color="error"
        icon={<AccountBalanceWalletIcon color="action" sx={{ fontSize: 50 }} />}
        />
    </Grid>
    </Grid>
    
    <Box sx={{ width: '100%', mt:5}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Agents" {...a11yProps(0)} />
            <Tab label="Clients" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Agents />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Clients />
        </CustomTabPanel>
      </Box>
    </ChiefAgentTemplate>
  );
  
};

export default Customers;

