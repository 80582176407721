import React, { useState } from 'react';
import axios from 'axios';
import { Container, Typography, TextField, Button, Box, } from '@mui/material';
import Alert from '@mui/material/Alert';

const FeedbackComponenent = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
      });
      const [errors, setErrors] = useState({});
      const [submitted, setSubmitted] = useState(false);
    
      // Handle input changes
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      // Form validation
      const validateForm = () => {
        const newErrors = {};
    
        if (!formData.name.trim()) {
          newErrors.name = 'Name is required';
        }
    
        if (!formData.email.trim()) {
          newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newErrors.email = 'Email address is invalid';
        }
    
        if (!formData.message.trim()) {
          newErrors.message = 'Message is required';
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
    
      // Handle form submission
      const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) return;
    
        try {
          await axios.post('https://interstation.ibrahimsherif.com/api/contact_us', formData);
          setSubmitted(true);
          // console.log(submitted)
        } catch (error) {
          // console.error("Failed to submit:", error);
        }
      };
return (
    // <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
    <section>
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Contact Us
      </Typography>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        onSubmit={handleSubmit}
      >
        <TextField
          label="Your Name"
          name="name"
          variant="outlined"
          fullWidth
          required
          value={formData.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          label="Your Email"
          name="email"
          type="email"
          variant="outlined"
          fullWidth
          required
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          label="Your Message"
          name="message"
          variant="outlined"
          multiline
          rows={6}
          fullWidth
          required
          value={formData.message}
          onChange={handleChange}
          error={!!errors.message}
          helperText={errors.message}
        />
        <Button type="submit" variant="contained" color="primary">
          Send Message
        </Button>
        {submitted && (
            <Alert variant="outlined" severity="success">
            Thank you for your message! We'll get back to you soon.
            </Alert>
        )}
      </Box>
    </Container>
  </section>
);
};
export default FeedbackComponenent;

