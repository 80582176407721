import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const  storedToken = JSON.parse(localStorage.getItem('token')); // or get it from your authentication context/state
  const userRole = storedToken?.data?.role;

  if (!userRole || !allowedRoles.includes(userRole)) {
    // If the user is not authenticated or doesn't have the correct role
    return <Navigate to="*" replace />;
  }

  return children;
};

export default ProtectedRoute;
