// AgentComponent.js
import React, { useRef, useEffect, useState } from 'react';
import CustomAccordion from '../../components/accordion';
import RepresentativeTemplate from './ReprentativeTemplate';

const NewUser = ({agentReferral, clientReferral}) => {
  const agentRef = useRef(null);
  const agencyRef = useRef(null);
  const clientRef = useRef(null);
  const [agencyReferral, setAgencyReferral] = useState('');

    useEffect(() => {

    const storedToken = JSON.parse(localStorage.getItem('token'));
    setAgencyReferral(storedToken.data.referral_code[0].code);
    
  }, []);

  const handleCopy = () => {
    
    const textToCopy = agentRef.current.textContent;
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert('Referral code copied to clipboard!');
    }).catch(err => {
      // console.error('Failed to copy text: ', err);
    });
  };
  const handleCopy2 = () => {
    const textToCopy = clientRef.current.textContent;
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert('Referral code copied to clipboard!');
    }).catch(err => {
      // console.error('Failed to copy text: ', err);
    });
  };
  const handleCopy3 = () => {
    const textToCopy = agencyRef.current.textContent;
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert('Referral code copied to clipboard!');
    }).catch(err => {
      // console.error('Failed to copy text: ', err);
    });
  };

  return (
    <RepresentativeTemplate>
    <CustomAccordion
    defaultExpanded={true}
      summaryText="Agency Referral Code"
      detailContent={
        <>
          <h4>Share your referral code to agencies to register under your name.</h4>
          <p>Your Referral code is: <strong ref={agencyRef}>{agencyReferral}</strong></p>
        </>
      }
      onCopy={handleCopy3}
    />
        <CustomAccordion
      summaryText="Agent Referral Code"
      detailContent={
        <>
          <h4>Share your referral code to agents to register under your name.</h4>
          <p>Your Referral code is: <strong ref={agentRef}>{agentReferral}</strong></p>
        </>
      }
      onCopy={handleCopy}
    />
        <CustomAccordion
        summaryText="Client Referral Code"
        detailContent={
        <>
          <h4>Share your referral code to clients to register under your name.</h4>
          <p>Your Referral code is: <strong ref={clientRef}>{clientReferral}</strong></p>
        </>
        }
        onCopy={handleCopy2}
        />
    </RepresentativeTemplate>
    
  );
};

export default NewUser;
