import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useState, useEffect  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { IconButton } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';

const defaultTheme = createTheme();

export default function PasswordReset() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const { token } = useParams();

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
      };
    
      const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
      };
      useEffect(() => {
        if (confirmPassword && password !== confirmPassword) {
          setError("Passwords don't match");
        } else {
          setError('');
        }
      }, [password, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
      try {
        const response = await axios.post('https://interstation.ibrahimsherif.com/api/reset_password', {
        token: token,
        password: password,
        });
        setLoading(false);
        navigate('/resetconfirm');
      } catch (error) {
        setLoading(false);
        setError(error.response.data.message);
      }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{ width: '100%', marginTop: '80px' }}>
        {loading && (
        <Box sx={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
          <LinearProgress />
        </Box>
      )}
      </Box>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'error.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Enter new password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

          <FormControl variant="outlined" fullWidth sx={{mb:2}}>
                    <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                    </InputAdornment>
                    }
                    label="Password"
                    name='password'
                    value={password}
                    onChange={handlePasswordChange}
                />
                </FormControl>

            <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password2">Confirm password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password2"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Retype password"
                    name='re_password'
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
              mt: 2,
              py: 1.5,
              bgcolor: '#B62D57',
              '&:hover': {
                bgcolor: '#F4D1B9',
                color:'#212121',
              }, }}
            >
              Submit
            </Button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
