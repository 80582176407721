const ChiefAgentListItems = [
    { to: '/admin-dashboard', primary: 'Dashboard', icon: 'DashboardIcon' },
    { to: '/new-user', primary: 'New User', icon: 'GroupAddIcon' },
    { to: '/admin-agency', primary: 'Agencies', icon: 'SupportAgentIcon' },
    { to: '/admin-agents', primary: 'Agents', icon: 'SupervisedUserCircleIcon' },
    { to: '/admin-clients', primary: 'Clients', icon: 'AccountCircleIcon' },
    { to: '/admin-contacts', primary: 'Contacts', icon: 'ContactsIcon' },
    { to: '/admin-report', primary: 'Report', icon: 'AssessmentIcon' },
  ];
  export default ChiefAgentListItems;
  