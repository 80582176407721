import { useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Modal } from '@mui/material';
import { Box } from '@mui/material';


const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'p',
    path:"/client-dashboard"
  },
  {
    label: 'Deposit',
    icon: 's',
    path:"/create"
  },
];

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [displayName, setDisplayName] = useState('Default Name');
  const [email, setEmail] = useState('email@email.com');
  const [role, setRole] = useState('email@email.com');
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleNext = () =>{
    setOpenModal(true);
  }
  const handleCancel = () =>{
    setOpenModal(false);
  }

  const handleClose = () => {
  setOpen(null);
  };

  const handleLogout = () => {
    localStorage.clear(); // Clear all items in localStorage
    navigate('/login'); // Navigate to the login page
  };
  const handleProfile = () => {
    navigate('/profile'); 
  };
  const account = async (token) => {
    try {
      const response = await axios.get('https://interstation.ibrahimsherif.com/api/referrals', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      setUserData([response.data]);
  
      // Assuming `userData` contains the fields you need, store them in localStorage
      
      setDisplayName(userData.data.first_name);
      // console.log(userData.data.first_name);
        setRole(userData.data.role);
        setEmail(userData.data.email);
        // const photoURL= userData.photoURL || '/images/default-avatar.jpg';
    } catch (error) {
      // console.error('Error fetching users:', error);
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={'images/inter-trans.PNG'}
          // alt={displayName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
            backgroundColor: '#f4d1b9', // Set the background color to white
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />

      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
          },
        }}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={handleProfile}
          
          sx={{ typography: 'body2', py: 1.5 }}
        >
          Profile
        </MenuItem>
        {/* <MenuItem
          disableRipple
          disableTouchRipple
          onClick={handleNotifications}
          
          sx={{ typography: 'body2', py: 1.5 }}
        >
          Notifications
        </MenuItem>
        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={handleSupport}
          
          sx={{ typography: 'body2', py: 1.5 }}
        >
          Contact us
        </MenuItem> */}
        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={handleNext}
          
          sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
        >
          Logout
        </MenuItem>
        <Modal
                      open={openModal}
                      onClose={handleCancel}
                      aria-labelledby="reauthenticate-modal-title"
                      aria-describedby="reauthenticate-modal-description"
                    >
                      <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        textAlign: 'center',
                      }}>
                        <Typography id="reauthenticate-modal-title" variant="h6">
                          Confirm logout
                        </Typography>
                        <Typography id="reauthenticate-modal-description" sx={{ mt: 2 }}>
                          Do you wish to logout?
                        </Typography>
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                          <Button variant="contained" color="error" onClick={handleLogout}>
                            Logout
                          </Button>
                          <Button variant="outlined" color="black" onClick={handleCancel}>
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    </Modal>
      </Popover>
    </>
  );
}
