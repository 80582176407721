import React, { useState, useEffect } from 'react';
import { Paper, Button } from '@mui/material';
import axios from 'axios';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';  
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

const columns = [
  {
    field: 'fullName',
    headerName: 'Full Name',
    width: 150,
    valueGetter: (value, row) => `${row.first_name || ''} ${row.last_name || ''}`,
    // valueGetter: (value, row) => `${row.first_name || ''} ${row.last_name || ''} ${row.color || ''}`,
  },
  {
    field: 'event',
    headerName: 'Event',
    width: 80,
    sortable: false,
    renderCell: (params) => (
      <IconButton
        sx={{color: 'red'}}
      >
        <LocalFireDepartmentIcon />
      </IconButton>
    ),
  },
  { field: 'telephone', headerName: 'Telephone', width: 110 },
  { field: 'email', headerName: 'Email', width: 130 },
  { field: 'age', headerName: 'Age', type: 'number', width: 60 },
  { field: 'city', headerName: 'City', width: 80 }, 
  { field: 'gender', headerName: 'Gender', width: 60 },
  { field: 'business_categories', headerName: 'Business Categories', width: 200 },
  { field: 'interests', headerName: 'Interests', width: 200 },
  {
    field: 'contact',
    headerName: 'Contact',
    width: 80,
    sortable: false,
    renderCell: (params) => (
      <IconButton
      sx={{color: 'green'}}
        onClick={() => handleWhatsAppContact(params.row.telephone)}
      >
        <WhatsAppIcon />
      </IconButton>
    ),
  },
  
];

  const handleWhatsAppContact = (phoneNumber) => {
    // Format phone number for WhatsApp link (without special characters)
    const formattedNumber = phoneNumber.replace(/\D/g, '');
    const whatsappUrl = `https://wa.me/+237${formattedNumber}`;
    
    // Open WhatsApp link in a new tab
    window.open(whatsappUrl, '_blank');
  };

const paginationModel = { page: 0, pageSize: 5 };

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

const ContactTable = () => {
  const [userData, setUserData] = useState([]);
  const [row, setRow] = useState([]);
  const [userCard, setUserCard] = useState('');
  const [userPrevCard, setUserPrevCard] = useState('');
  const [bearerToken, setBearerToken] = useState('');



  const fetchUsers = async (token) => {
    try {
      const response = await axios.get('https://interstation.ibrahimsherif.com/api/contacts', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(response.data.data);
      setRow(response.data.data);
    } catch (error) {
      // console.error('Error fetching users:', error);
    }
  };

  const fetchCards = async (token) => {
    try {
      const response = await axios.get('https://interstation.ibrahimsherif.com/api/download_contacts', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserCard(response.data);
    } catch (error) {
      // console.error('Error fetching contacts:', error);
    }
  };

  const fetchPrevCards = async (token) => {
    try {
      const response = await axios.get('https://interstation.ibrahimsherif.com/api/recover_contacts', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserPrevCard(response.data);
    } catch (error) {
      // console.error('Error fetching previous contacts:', error);
    }
  };

  useEffect(() => {
    const contactData = JSON.parse(localStorage.getItem('token'));
    if (contactData) {
      const token = contactData.data.token;
      setBearerToken(token);
      fetchUsers(token);
    }
  }, []);

  const handleExportPrevious = async () => {
    const userConfirmed = window.confirm("You are in the process of downloading previous contacts, do you wish to proceed?");
    if (userConfirmed) {
      const contactData = JSON.parse(localStorage.getItem('token'));
      if (contactData) {
        await fetchPrevCards(contactData.data.token);
        const blob = new Blob([userPrevCard], { type: 'text/vcard' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'previous_contacts.vcf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
  };

  const handleExport = async () => {
    const userConfirmed = window.confirm("You are in the process of downloading contacts, do you wish to proceed?");
    if (userConfirmed) {
      const contactData = JSON.parse(localStorage.getItem('token'));
      if (contactData) {
        await fetchCards(contactData.data.token);
        const blob = new Blob([userCard], { type: 'text/vcard' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'contacts.vcf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <h2>Contacts</h2>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportPrevious}
            style={{ margin: '5px' }}
          >
            Recover Contacts
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExport}
            style={{ margin: '5px' }}
          >
            Export New
          </Button>
        </div>
      </div>
      <Paper sx={{ height: 400, width: '100%' }}>
        <DataGrid
          slots={{
            toolbar: CustomToolbar,
          }}
          rows={row}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[5, 10, 25]}
          getRowId={(row) => row.telephone}
          sx={{ border: 0 }}
        />
      </Paper>
    </div>
  );
};

export default ContactTable;
