import React from 'react';
import { useNavigate } from 'react-router-dom';

const ResetConfirm = () => {
  const navigate = useNavigate();

  const handleGoToDashboard = () => {
    navigate('/login'); // Replace with the actual route to your dashboard
  };
  const imageStyle = {
    width: '50%',
    maxWidth: '100px',
    height: 'auto',
    animation: 'scale-up-center 1s ease-in-out both',
  };
  return (
    <div style={styles.container}>
    <style>
        {`
          @-webkit-keyframes scale-up-center {
            0% {
              -webkit-transform: scale(0.5);
              transform: scale(0.5);
            }
            100% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }

          @keyframes scale-up-center {
            0% {
              -webkit-transform: scale(0.5);
              transform: scale(0.5);
            }
            100% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
        `}
      </style>
      <h1 style={styles.title}>Password reset successfully</h1>
      <img
        src='/images/check-mark.png'
        alt='Landing Page'
        style={imageStyle}
        mb={2}
      />
      {/* <AccountPopover /> */}
      <button onClick={handleGoToDashboard} style={styles.button}>
        Go to Login
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
  },
  title: {
    fontSize: '28px',
    marginBottom: '20px',
    color: '#333',
  },
  button: {
    padding: '12px 24px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#b62d57',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default ResetConfirm;
