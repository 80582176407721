import React, { useEffect, useRef, useState  } from 'react';
import Alert from '@mui/material/Alert';
import axios from 'axios';

const LandingPage = () => {
  const headerRef = useRef(null);
  const sectionsRef = useRef([]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email address is invalid';
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    try {
      await axios.post('https://interstation.ibrahimsherif.com/api/contact_us', formData);
      setSubmitted(true);
      // console.log(submitted)
    } catch (error) {
      console.error("Failed to submit:", error);
    }
  };

  useEffect(() => {
    import('../styles/LandingPage.css');
    const handleScroll = () => {
      if (window.scrollY > 50) {
        headerRef.current.classList.add('scrolled');
      } else {
        headerRef.current.classList.remove('scrolled');
      }

      // Parallax effect
      const parallaxElements = document.querySelectorAll('.hero::before, .about::before');
      parallaxElements.forEach(el => {
        const scrolled = window.pageYOffset;
        const limit = el.offsetTop + el.offsetHeight;
        if (scrolled > el.offsetTop && scrolled <= limit) {
          el.style.backgroundPositionY = (scrolled - el.offsetTop) / 1.5 + 'px';
        } else {
          el.style.backgroundPositionY = '0';
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    // Intersection Observer for section animations
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const sectionObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    sectionsRef.current.forEach(section => {
      sectionObserver.observe(section);
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      sectionObserver.disconnect();
    };
  }, []);

  const handleNavClick = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    const headerOffset = 60;
    const elementPosition = targetElement.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };

  

  return (
    <>
      <header ref={headerRef} className='typeHead'>
        <a href="#" className="logo" aria-label="Interstation">
          <img src="/images/inter-trans.PNG" alt="Interstation logo" width="40" height="40" />
          <span className="logo-text">Interstation</span>
        </a>
        <nav>
          <a href="#features" className="nav-link" onClick={(e) => handleNavClick(e, 'features')}>Features</a>
          <a href="#about" className="nav-link" onClick={(e) => handleNavClick(e, 'about')}>About</a>
          <a href="#contact" className="nav-link" onClick={(e) => handleNavClick(e, 'contact')}>Contact</a>
        </nav>
      </header>
      <main>
        <section id="hero" className="hero" ref={el => sectionsRef.current[0] = el}>
          <div className="container">
            <h1>Welcome to Interstation</h1>
            <p>Connect, collaborate, and network with professionals worldwide using our innovative platform.</p>
            <div className="midButton">
              <span className="button button-primary nav-link">
              <a href="https://cm.interstationconnect.app/login" target="_blank" rel="noopener noreferrer">Go to Interstation</a></span>
              <a href="#about" className="button button-outline nav-link" onClick={(e) => handleNavClick(e, 'about')}>Learn More</a>
            </div>
            {/* <img src="/placeholder.svg?height=300&width=500" alt="Networking illustration" className="floating" style={{ maxWidth: '100%', height: 'auto', marginTop: '2rem' }} /> */}
          </div>
        </section>
        <section id="features" className="features" ref={el => sectionsRef.current[1] = el}>
          <div className="container">
            <h2>Platform Features</h2>
            <div className="features-grid">
              {/* Feature items */}
              <div className="feature">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#be185d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>
                <h3>Smart Networking</h3>
                <p>Connect with professionals based on shared interests, skills, and career goals using our AI-powered matching algorithm.</p>
              </div>
              {/* Add other feature items here */}
            </div>
          </div>
        </section>
        <section id="about" className="about" ref={el => sectionsRef.current[2] = el}>
          <div className="container">
            <h2>About Interstation</h2>
            <p>Interstation is a cutting-edge professional networking platform designed to connect individuals and businesses across the globe. Our mission is to empower professionals by providing them with the tools and opportunities to expand their network, share knowledge, and accelerate their careers.</p>
            <p>Its objective is to, whatever the sector of activity, offer the possibility to its user to constitute an address book according to their sector of activity and their interests. It is an essential tool capable of adapting perfectly to variations in user needs.</p>
          </div>
        </section>
        <section id="contact" className="contact" ref={el => sectionsRef.current[3] = el}>
          <div className="container">
            <h2>Contact Us</h2>
            <form>
              <input name="name" type="text" placeholder="Your Name" aria-label="Your Name" value={formData.name} 
              onChange={handleChange} error={!!errors.name} helperText={errors.name} required />
              <input name="email" type="email" placeholder="Your Email" aria-label="Your Email" value={formData.email}
          onChange={handleChange} error={!!errors.email} helperText={errors.email} required />
              <textarea name="message" placeholder="Your Message" aria-label="Your Message" rows="4"
              value={formData.message} onChange={handleChange} error={!!errors.message}
          helperText={errors.message} required></textarea>
              <button type="submit" onClick={handleSubmit}>Send Message</button>
              {submitted && (
            <Alert variant="outlined" severity="success" sx={{mt: 2}}>
            Thank you for your message! We'll get back to you soon.
            </Alert>
        )}
            </form>
          </div>
        </section>
      </main>
      <footer>
        <p>&copy; 2023 Interstation. All rights reserved.</p>
        <nav>
          <a href="#features" className="nav-link" onClick={(e) => handleNavClick(e, 'features')}>Features</a>
          <a href="#about" className="nav-link" onClick={(e) => handleNavClick(e, 'about')}>About</a>
          <a href="#contact" className="nav-link" onClick={(e) => handleNavClick(e, 'contact')}>Contact</a>
        </nav>
      </footer>
    </>
  );
};


export default LandingPage;
