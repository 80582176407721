import * as React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ChiefAgentListItems from './ChiefAgentListItems';


const ChiefAgentTemplate = ({ children }) => {

    return (
    <Header list={ChiefAgentListItems} role={"Chief Agent"}>
        {children}
        <Footer/>
    </Header>
    );
};

export default ChiefAgentTemplate;
