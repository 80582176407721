import { Box, Card, CardContent, Typography, Stack } from '@mui/material'

export default function AppWidgetSummary({ title, total, icon, color = 'primary', sx, ...other }) {
  return (
    // <Card
    //   component={Stack}
    //   spacing={3}
    //   direction="row"
    //   sx={{
    //     px: 3,
    //     py: 5,
    //     borderRadius: 2,
    //     ...sx,
    //   }}
    //   {...other}
    // >
    //   {icon && <Box sx={{ width: 64, height: 64 }}>{icon}</Box>}

    //   <Stack spacing={0.5}>
    //     <Typography variant="h4">{total}</Typography>

    //     <Typography variant="subtitle2" sx={{ color: 'text.disabled' }}>
    //       {title}
    //     </Typography>
    //   </Stack>
    // </Card>
    <Card 
    component={Stack}
    sx={{ 
      flex: 1,
      bgcolor: '#FFF9F0',
      borderRadius: 2,
      '&:hover': {
        boxShadow: 3,
        transform: 'translateY(-2px)',
        transition: 'all 0.2s ease-in-out'
      }
    }}
    {...other}
  >
    <CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Box>
          <Typography 
            variant="h4" 
            component="div" 
            sx={{ 
              fontWeight: 'bold',
              color: '#FF9800'
            }}
          >
            {total}
          </Typography>
          <Typography 
            variant="body2" 
            sx={{ 
              color: '#FF9800',
              opacity: 0.8,
              mt: 0.5 
            }}
          >
            {title}
          </Typography>
        </Box>
        {icon && <Box sx={{ width: 64, height: 64 }}>{icon}</Box>}
      </Box>
    </CardContent>
  </Card>
  );
}

