// NotificationsPage.jsx
import React, { useState } from 'react';
import { Container, Typography, Badge } from '@mui/material';
import NotificationCard from './NotificationCard';

const initialNotifications = [
  {
    id: 1,
    avatar: 'path/to/avatar1.jpg',
    title: 'Tim invited you to join the Events Inc. organization',
    message: '2 hours ago',
  },
  {
    id: 2,
    avatar: 'path/to/avatar2.jpg',
    title: 'Objective C will be closed in 3 days. Go there to finish your remaining tasks.',
    message: '5 hours ago',
  },
  {
    id: 3,
    avatar: 'path/to/avatar3.jpg',
    title: 'John commented on the Healthcare challenge that you are participating.',
    message: 'Yesterday',
  },
  {
    id: 4,
    avatar: 'path/to/avatar4.jpg',
    title: 'Python will be closed in 3 days. Go there to finish your remaining tasks.',
    message: '3 days ago',
  },
  {
    id: 5,
    avatar: 'path/to/avatar5.jpg',
    title: 'Max invited you to join the Microsoft organization',
    message: '5 days ago',
  },
  {
    id: 6,
    avatar: 'path/to/avatar6.jpg',
    title: 'JavaScript MD will be closed in 3 days. Go there to finish your remaining tasks.',
    message: '1 week ago',
  },
];

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState(initialNotifications);

  const handleDelete = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };

  return (
    <Container>
      <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        All Notifications <Badge badgeContent={notifications.length} color="primary" sx={{ ml: 2 }} />
      </Typography>
      {notifications.map((notification) => (
        <NotificationCard
          key={notification.id}
          notification={notification}
          onDelete={() => handleDelete(notification.id)}
        />
      ))}
    </Container>
  );
};

export default NotificationsPage;
